@import "../../styles/common";

.confirm-dialog {
  .header {
    @extend %modal-header;

    .title {
      @extend %modal-header-title;
    }
  }

  .body {
    display: flex;
    padding: 20px;
  }

  .footer {
    padding: 10px;
    border: none;
  }
}
