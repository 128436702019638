.claim-modal {
  max-width: 1320px;
  width: 100%;
  .form-input-toggle {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0px;
    }
  }
}

.unclaimed-message {
  min-height: 60px;
  background-color: #314961;
  display: flex;
  align-items: center;
  font-size: 18px;
  padding-left: 20px;
  color: #f8f8fc;
  margin: 15px 10px;
  font-weight: bold;
}
