.order-modal {
  max-width: 1420px;
  width: 100%;

  .delivery-title {
    height: 60px;
    font-weight: bold;
    line-height: 60px;
  }

  .overflow-auto {
    overflow: visible !important;
  }
  .form-input-toggle {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0px;
    }
  }
}
