@import "../../styles/common";

.left-side-modal {
  .modal-backdrop:not([aria-hidden="true"]) {
    z-index: 1050;
  }

  .fade {
    transition: none;
  }

  &.show {
    .left-modal-container {
      transform: translateX(0) !important;
    }
  }
}

.left-modal-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 680px;
  max-width: 680px;
  height: 100%;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  box-shadow: 3px 0 5px rgba(0, 0, 0, 0.15);
  margin: 0;
  transform: translateX(-680px) !important;
  -webkit-transition: 1s ease-in-out;
  -moz-transition: 1s ease-in-out;
  -o-transition: 1s ease-in-out;
  transition: 1s ease-in-out;

  &.wide {
    width: 100%;
    max-width: 960px;
  }

  .modal-content {
    border: none;
    outline: 0;
  }

  .header {
    @extend %modal-header;

    .title {
      @extend %modal-header-title;
    }
    .close {
      text-shadow: none;
      color: #fff;
      opacity: 1;
      outline: none;
      i {
        font-size: 32px;
      }
      &:active,
      &:focus,
      &:hover {
        background-color: transparent;
        border: 0;
      }
    }
  }

  .body {
    display: flex;
    padding: 40px;
  }

  .footer {
    padding: 40px;
    border: none;

    .bottom-message {
      display: flex;
      flex: 1 1 auto;
      align-items: center;
      justify-content: flex-start;
      max-width: 320px;

      .message {
        font-size: 20px;
        color: #7d829e;
        height: 100%;
        padding-left: 10px;
      }

      .message-icon {
        width: 40px;
        color: #7d829e;
        height: 40px;
        font-size: 40px;
      }
    }

    .button {
      width: 120px;
    }

    .left-button-container {
      display: flex;
      flex: 1 1 auto;
      justify-content: flex-start;

      .left-button {
        width: auto;
      }
    }
  }
}

@media (max-width: 920px) {
  .left-modal-container {
    max-width: 100%;
    .body {
      padding: 20px;
    }
  }
}
