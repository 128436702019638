@import "../../../styles/common";
@import "../../../styles/variables.scss";

.quote-selection-categories {
  max-height: 100%;

  .quote-selection-body {
    min-height: 200px;
    max-height: calc(100vh - 260px);
  }

  .quote-selection-list {
    .quote-selection-list-item {
      @extend %category-item;
      box-shadow: 0 1px 0 0 #d1d2de;
      margin-bottom: 1px;

      &:hover {
        background-color: #fcf8f8;
      }

      .right-side {
        display: flex;
        align-items: center;

        .badge {
          font-size: 14px;
          padding: 5px 10px;
        }

        .item-total {
          margin-right: 15px;
        }

        .remove-action {
          height: 50px;
          width: 24px;
          display: flex;
          justify-content: center;
          align-items: center;
          user-select: none;
          cursor: pointer;

          i {
            font-size: 24px;
          }
        }
      }

      .unallocatedItems-icon {
        margin-right: 5px;
        font-size: 19px;
        color: $color-error;
      }

      .item-name {
        display: flex;
        align-items: center;
      }
    }
  }

  .selection-category-selected {
    background-color: #fcf8f8;
  }

  .add-category {
    width: 100%;
    margin-bottom: 40px;
  }

  .save-selection-button {
    width: 200px;
  }
}
