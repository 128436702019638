.filters-container {
  width: calc(100% - 100px);
  position: fixed;
  top: 60px;
  z-index: 3;
  padding: 20px 0px;
  background-color: white;

  .grid-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 60px;
    margin-right: 10px;
    background-color: #314961;
    color: white;
    outline: none;
    border: none;
    & i {
      font-size: 30px;
    }
  }
}
