.quote-request-list {
  max-height: 100%;
  height: 100%;

  .quote-request-header {
    padding: 0;
    justify-content: center;
  }

  .quote-request-sq-list {
    &--open {
      .quote-request-quotes-list {
        max-height: 100vh !important;
      }
    }

    .quote-request-sq-item {
      padding: 1rem 1.25rem;
      border-bottom: 1px solid #dee2e6;
      align-items: center;
      display: flex;
      cursor: pointer;

      &--title {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    .material-icons {
      font-size: 32px;
      line-height: 0;
    }

    span {
      margin-left: 0.5rem;
      font-size: 0.8em;
      white-space: nowrap;
    }

    .quote-request-quotes-list {
      max-height: 0px;
      overflow: hidden;
      transition: max-height 0.25s ease-in-out;
      padding: 0;
      margin: 0;

      &--selected {
        background-color: #f8f8fc;
      }

      .quote-request-quote-item {
        padding: 1rem 1.25rem;
        border-bottom: 1px solid lighten(#dee2e6, 5%);
        align-items: center;
        display: flex;
        cursor: pointer;

        &--title {
          list-style-type: disc;
          display: list-item;
          margin-left: 20px;
        }
      }
    }
  }
}
