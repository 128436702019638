.order-receive-modal {
  max-width: 1420px;
  width: 100%;

  .receipts-title {
    height: 60px;
    font-weight: bold;
    line-height: 60px;
    margin-bottom: 30px;
  }

  .uploader {
    width: 80%;
  }

  .uploader-table {
    .table-card {
      width: 80%;
      .dashboard-card {
        .body {
          padding: 0 30px;
          margin: 0px;
        }
      }
    }
  }

  .overflow-auto {
    overflow: visible !important;
  }
}
