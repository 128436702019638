.edit-company-modal {
  .avatar-uploader {
    border-radius: 0;
    width: auto;
    text-align: center;
    height: 150px;
    .rounded-circle {
      border-radius: 0 !important;
      width: auto !important;
      // height: auto !important;
      max-height: 100%;
      max-width: 100%;
    }
  }
}
