.schedule-container {
  display: flex;
  height: 100%;
  width: 100%;

  .super-large {
    min-width: 260px;
  }

  .calendar-item-name {
    touch-action: none;
    pointer-events: none;
  }
}

.schedule-body {
  width: 100%;
  .body {
    margin: 0;
    .empty-placeholder {
      margin-top: 40px;
    }
  }

  // max-height: 820px;
  .actions-container {
    margin-left: auto;
  }

  .datePicker-content * {
    line-height: 1.3rem !important;
    overflow: initial;
    vertical-align: middle;
  }
  .datePickerStyle {
    width: 100%;
    outline: none;
    background: rgba(49, 73, 97, 0.1);
    vertical-align: middle;
  }
}
