.sort-tasks-modal {
  .item {
    background: #fff;
    border: 1px solid #ccc;
    padding: 10px 20px 10px 40px;
  }
  .item-category {
    background-color: rgba(49, 73, 97, 0.2);
    border-left: 3px solid #314961;
    margin-top: 10px;
    &:before {
      content: "";
      position: absolute;
      display: list-item;
      list-style-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNNy40MSA4LjU5TDEyIDEzLjE3bDQuNTktNC41OEwxOCAxMGwtNiA2LTYtNiAxLjQxLTEuNDF6Ii8+PHBhdGggZmlsbD0ibm9uZSIgZD0iTTAgMGgyNHYyNEgwVjB6Ii8+PC9zdmc+");
    }
  }
  .item-task {
    background-color: rgba(49, 73, 97, 0.1);
    padding-left: 60px;
    margin-bottom: -1px;
    border-left: 3px solid #46627d;
    &:before {
      content: "";
      position: absolute;
      display: list-item;
      list-style-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48ZWxsaXBzZSByeT0iNCIgcng9IjQiIGlkPSJzdmdfMSIgY3k9IjEyIiBjeD0iMTIiIGZpbGw9IiMwMDAwMDBCMCIvPjwvc3ZnPg==");
    }
  }
}
