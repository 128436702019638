.file-input {
  cursor: pointer;
  user-select: none;
  height: 300px;
  background-color: #f8f8fc;
  border: 1px solid #7d829e;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;

  .text-container {
    padding: 0 10px;
    text-align: center;

    i {
      font-size: 96px;
    }
  }

  .input-title {
    font-weight: bold;
    margin-top: 10px;
  }
}
