.plans-upload-card {
  max-height: 100%;

  &.inactive {
    opacity: 0.5;
    pointer-events: none;
  }

  .header {
    text-transform: none;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .drag-and-drop-zone {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 300px;
    background-color: #f8f8fc;
    border: 1px solid #7d829e;

    i {
      height: 96px;
      width: 96px;
      font-size: 96px;
      color: #7d829e;
    }

    .description {
      color: #7d829e;

      .field-text {
        text-align: center;
      }
    }
  }
}
