.table-card {
  .estimation-name {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .cell-truncate {
    max-width: 175px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}
