.checkbox-container {
  width: 100%;
  height: 60px;
  .checkbox-icon {
    justify-content: center;
    align-items: center;
    color: #7d829e;
    cursor: pointer;
  }
  .label {
    height: 100%;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .input {
    display: none;
  }
}
