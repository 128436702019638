.home-overview {
  .body {
    margin: 0;
    max-height: 300px;
  }
  .empty-placeholder {
    .empty-message {
      font-size: 20px;
      margin: 20px;
    }
    .button {
      height: 50px;
      font-size: 16px;
      margin-bottom: 20px;
    }
  }
}
