@import "../../../styles/common";
@import "../../../styles/variables";

.site-diary-entry {
  .site-diary-title {
    @extend %message-header;
    color: #314961;
  }

  .site-diary-entry-overview {
    display: flex;
    flex-direction: row;
    flex: 1;
    margin: 15px 0;

    .site-diary-entry-overview-item {
      display: flex;
      flex-direction: row;
      color: $text-color-secondary;
      font-weight: 500;
      align-items: center;
      margin-right: 20px;

      .material-icons {
        margin-right: 10px;
      }
    }
  }
  .site-diary-entry-note {
    color: #314961;
  }
  .site-diary-entry-question {
    display: flex;
    margin: 20px 0;
    white-space: pre-line;
    .site-diary-entry-id {
      width: 30px;
    }
    .site-diary-entry-id,
    .site-diary-entry-title {
      color: #7d829e;
      font-size: 24px;
      line-height: 1.2;
      font-weight: 500;
    }
    .site-diary-table-cell-photo {
      img {
        max-height: 50px;
        max-width: 50px;
        margin: 0 0 0 5px;
      }
    }
    .site-diary-table-row-photo {
      display: none;
      height: auto;
      td {
        border-top: 0;
      }
    }
  }
  img {
    max-height: 100px;
    max-width: 150px;
  }

  .field-text {
    white-space: pre-line;
  }
}

.site-diary-footer {
  justify-content: flex-end;
  display: flex;
}

.site-diary-print {
  display: none;
}

@media print {
  .site-diary-print {
    width: 100%;
    display: block;
    page-break-after: always;
    .site-diary-table-cell-photo {
      display: none;
    }
    .site-diary-table-row-photo {
      display: table-row !important;
    }
    img {
      max-height: 200px;
      max-width: 200px;
    }
  }
}
