.leaflet-control-container {
  display: none;
}

%toolbar-controls {
  display: none;
  position: absolute;
  top: 60px;
}

.plan-viewer-toolbar {
  position: absolute;
  top: 20px;
  height: 50px;
  display: flex;
  z-index: 1020;
  &-left {
    left: 20px;
    min-width: 250px;
  }
  &-right {
    right: 20px;
  }

  .buttons-container {
    display: flex;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  }

  .control-button {
    height: 40px;
  }

  .toolbar-button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 50px;
    background-color: #f8f8fc;
    cursor: pointer;
    user-select: none;
    flex-shrink: 0;

    i {
      font-size: 30px;
      color: #7d829e;
    }

    .png-icon {
      svg {
        fill: #7d829e;
      }
    }

    .edit-controls {
      @extend %toolbar-controls;
      left: unset;
    }

    .button-input {
      @extend %toolbar-controls;
      left: 0;
      max-width: 280px;
      .text-input {
        padding: 0 10px !important;
      }
      .form-input-text {
        border-radius: 0;
        background-color: #f8f8fc;
        height: 60px;
      }
    }

    &.enabled {
      .edit-controls {
        display: block;
      }

      .button-input {
        display: block;
      }
    }

    &.disabled,
    &.disabled:hover,
    &.disabled:active {
      background-color: #f8f8fc;
      opacity: 0.7;

      i {
        color: #7d829e;
      }

      .png-icon {
        svg {
          fill: #7d829e;
        }
      }
    }

    &.enabled,
    &:active,
    &:hover {
      background-color: #7d829e;

      i {
        color: #f8f8fc;
      }

      .png-icon {
        svg {
          fill: #f8f8fc;
        }
      }
    }

    &:active {
      background-color: darken(#7d829e, 20%);
    }
  }
}
