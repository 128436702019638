.quote-request-modal {
  width: 100%;
  max-width: 1380px;
  .modal-footer {
    border-top: 1px solid #d1d2de;
  }
}

.plan-container {
  // display: flex;
  // flex-wrap: wrap;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));

  .plan {
    border: 1px solid #d1d2de;
    // flex: 1 0 25%;
    // width: 150px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    margin-bottom: 10px;
    position: relative;
    cursor: pointer;

    .plan-preview-img {
      max-width: 100%;
      max-height: 100%;
      // max-height: 95px;
    }

    &.is-selected {
      border: 2px solid #3fbb64;
    }

    .selection-mark {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 40px;
      height: 40px;
      background: #3fbb64;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #ffffff;
      z-index: 1;

      i {
        font-size: 30px;
      }
    }
  }
}
