@import "../../../../styles/common";

.price-list-categories {
  max-height: 100%;

  .price-list-category-list {
    .price-list-category-item {
      @extend %category-item;
      box-shadow: 0 1px 0 0 #d1d2de;
      margin-bottom: 1px;

      &:hover {
        background-color: #fcf8f8;
      }

      .right-side {
        display: flex;
        align-items: center;

        .item-count {
          margin-right: 10px;
        }

        .item-total {
          margin-right: 15px;
        }

        .remove-action {
          height: 60px;
          width: 24px;
          display: flex;
          justify-content: center;
          align-items: center;
          user-select: none;
          cursor: pointer;

          i {
            font-size: 24px;
          }
        }
      }
    }
  }

  .price-list-category-selected {
    background-color: #fcf8f8;
  }

  .add-category {
    width: 100%;
    margin-bottom: 40px;
  }
}
