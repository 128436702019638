.avatar-fallback {
  user-select: none;
  cursor: pointer;
  background-color: #7d829e;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #feffff;
  overflow: hidden;
}

.avatar-fallback-dark {
  background-color: #22252b;
}

.avatar-fallback-light {
  background-color: #ebecee;
  color: #7d829e;
}

.avatar-outlined {
  border: 2px solid #314961;
}
