@import "~leaflet/dist/leaflet.css";
@import "~leaflet-draw/dist/leaflet.draw.css";

.plan-viewer {
  height: 100%;
  flex: 1;
  display: flex;

  .plan-viewer-map {
    flex: 1;
    display: flex;
    // height: 100%;

    .leaflet-draw-guide-dash {
      z-index: 1;
    }
    &.horizontal {
      .leaflet-overlay-pane {
        transform: rotateZ(90deg);
      }
    }
    .plan-viewer-toolbar {
      .input-group {
        z-index: 10;
      }
    }
  }
}
