.dashboard-dropdown {
  &-item {
    height: 50px;
    text-align: left;
    width: 100%;
    padding: 0 15px;
    border: none;
    justify-content: flex-start;
    background-color: #314961;
    color: white;
    margin-left: 0 !important;

    &:hover,
    &:active,
    &:focus {
      color: white;
      box-shadow: none;
      background-color: #314961;
    }

    &:hover {
      opacity: 0.95;
    }
  }

  &-item--light {
    height: 40px;
    padding: 0 10px;
    background-color: white;
    color: #7d829e;
    font-weight: normal;
  }

  .show > &-toggle,
  .show > &-toggle.btn-primary {
    background-color: lighten(#314961, 10%);
  }
  &-split {
    min-width: 60px;
  }
  &-split-dropdown {
    min-width: 60px;
  }

  &-toggle {
    background-color: #314961;
    margin: 0;

    &:after {
      display: none;
    }

    &:hover,
    &:focus,
    &.active,
    &:active {
      background-color: lighten(#314961, 10%);
      border-color: lighten(#314961, 10%);
      box-shadow: none !important;
      outline: none;
    }
    &.disabled,
    &:disabled {
      background-color: lighten(#314961, 10%);
      border-color: lighten(#314961, 10%);
    }
  }

  &-toggle--light {
    width: unset;
    height: unset;
    color: #7d829e;
    background-color: transparent;
    padding: 6px;
  }

  &-menu {
    max-height: 490px;
    overflow: auto;
    text-transform: capitalize;
    min-width: 100%;
    border-radius: unset;
    padding: 0;
    min-width: 140px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }
  &-filter {
    margin-bottom: 20px;
    .dashboard-dropdown-toggle {
      justify-content: space-between;
    }
    .dropdown-menu {
      max-width: 300px;
      overflow-x: hidden;
      .dropdown-item {
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 400px;
      }
    }
  }
}
