@import "../../../../styles/variables";
.plan-card {
  .card-header {
    background-color: $color-primary;
    color: #fff;
    padding: 1.5rem 1.25rem;
    h3 {
      margin: 0;
    }
  }
  .card-body {
    h4 {
      font-size: 36px;
    }
    ul {
      li {
        border-bottom: 1px solid rgba(153, 153, 153, 0.3);
        padding: 12px 0;
      }
    }
  }
  .card-footer {
    border: 0;
    background-color: transparent;
    display: flex;
    justify-content: center;
    padding-bottom: 1.5rem;
  }
}
