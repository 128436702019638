.inner-container {
  position: relative;
  height: calc(100vh - 180px);
  margin-top: 80px;
  overflow: auto;
  .leads-container {
    width: 100%;
    margin: 0 auto;
    display: flex;

    .addColumnIcon {
      display: block;
      font-size: 30px;
      color: #7d829e;
    }
    .addColumnIconContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      background-color: #f4f5f7;
      border: none;
      outline: none;
      cursor: pointer;
      & i {
        transition: all 0.1s linear;
      }
      & i:hover {
        background-color: rgba(9, 30, 66, 0.08);
        transform: scale(1.05);
      }
    }
    &.loader-center {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
    }
  }
}
