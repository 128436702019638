.details-card {
  .header {
    // justify-content: center;
    // text-transform: none;
    // padding: 0;
  }

  .body {
    overflow: hidden;
  }

  .details-card-list {
    padding: 0;
    list-style: none;
    // overflow: hidden;

    height: 100%;
    width: 100%;

    .details-card-list-item {
      display: flex;
      align-items: center;
      padding: 15px;
      margin-bottom: 20px;

      min-height: 60px;
      width: 100%;

      background-color: #f8f8fc;

      i {
        margin-right: 15px;
        font-size: 30px;
      }
      div {
        white-space: pre-line;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .footer {
    display: flex;
    justify-content: flex-end;
  }
}
