@import "../../../styles/variables.scss";

.quote-selection-container {
  .borderBottom {
    border-bottom: 3px solid $border-color-light;
  }

  .quote-selection-item {
    padding: 20px 10px;
    // display: flex;
    justify-content: space-between;

    .badge {
      padding: 5px 15px;
      display: inline-block;
      border-radius: 0px;
      font-size: 16px;
      font-weight: 400;
      max-height: 26px;
    }

    .item-title {
      display: flex;
      align-items: center;
      font-size: 20px;
      font-weight: 500;
      color: $color-primary;
    }

    .item-costing-path {
      margin-top: 5px;
      margin-left: 35px;
      color: $color-primary-light;
      font-weight: 700;
      font-size: 14px;
    }

    .item-description {
      margin-top: 5px;
      margin-left: 35px;
      white-space: pre-line;
    }

    .item-dueDate {
      margin-top: 5px;
      margin-left: 35px;
      font-size: 14px;
      font-weight: 700;
    }

    .expand-button {
      margin-right: 10px;
      cursor: pointer;
      font-size: 26px;
    }
  }

  .dashboard-dropdown {
    button {
      height: 40px;
      font-size: 16px;
    }

    i {
      font-size: 20px;
    }
  }

  .total-selected-option {
    margin-right: 10px;
    font-size: 17px;
    color: $color-primary-dark;
    font-weight: bold;
  }

  .badge-warning {
    max-height: 26px;
  }

  .add-option-button {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .create-button {
    margin-right: 8px;
    height: 40px;
    font-size: 16px;

    i {
      font-size: 20px;
    }
  }

  .color-primary {
    background: $color-primary;
  }

  .color-error {
    color: $color-error;
  }
}
