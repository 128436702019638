@import "../../../styles/variables";

.dashboard-tasks {
  margin-bottom: 30px;
}

.dashboard-task {
  display: flex;
  color: $text-color-primary;
  align-items: center;
  padding: 20px 20px 0 20px;
  cursor: pointer;

  &:last-child {
    padding-bottom: 20px;
  }

  .badge {
    width: 40px;
    margin-right: 15px;
  }

  .dashboard-task-message {
    .badge {
      width: 80px;
    }
  }

  .task-icon {
    color: $color-primary;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    .material-icons {
      font-size: 24px;
    }
  }

  .task-date {
    margin-left: 1em;
    color: #aaa;
    white-space: nowrap;
    font-size: 14px;
  }
}
