.documents-wrapper {
  .breadcrumb-buttons {
    background-color: transparent;
    padding: 0;
    margin: 0;
    min-width: auto;

    &:hover {
      background-color: transparent;
    }

    &:active {
      border: none !important;
      background-color: transparent !important;
    }

    &:focus {
      box-shadow: none !important;
      background-color: transparent !important;
    }
  }

  .breadcrumb-delimiter {
    padding: 0 8px;
    cursor: default;
  }

  .table-card {
    margin: 0;
    padding: 0;

    .body {
      min-height: 200px;
    }

    .dashboard-card {
      .footer {
        justify-content: flex-start;

        button {
          margin-left: 0px;
        }
      }
    }
  }

  .documents-button-wrapper {
    margin-left: auto;
    margin-right: 20px;
    display: flex;

    button {
      background-color: transparent;
    }

    .delete-button {
      width: 220px;
    }
  }
  .dashboard-card {
    .body {
      overflow: inherit;
    }
  }
}
