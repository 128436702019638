@import "../../../styles/variables.scss";

.import-from-template-modal {
  max-width: 680px;
  width: 100%;

  .form-input-label {
    font-size: 18px;
  }

  .table-responsive {
    max-height: calc(100vh - 330px);
    overflow-y: visible;
    position: relative;

    .table-header {
      .checkbox-container {
        padding-left: 6px;
      }
    }
  }

  .footer,
  .modal-body {
    padding: 20px 40px;
  }
}
