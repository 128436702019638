@import "../../styles/variables";

.form-input {
  min-width: 135px;
}
.form-input-group .append .text {
  &.input-group-append-compact {
    font-size: 20px;
    min-width: 40px;
    i {
      font-size: 20px;
    }
  }
}

.generic-form-body {
  padding: 0;

  .row {
    margin: 0;

    .form-column {
      padding-right: 10px;
      padding-left: 10px;
    }
  }
  .col-no-gutters {
    padding: 0;
  }

  .row-m {
    margin: 0 -10px;
  }
}

.table-card {
  .table-row-draggable-header {
    display: flex;
    z-index: 2;
    position: sticky;
    background-color: white;
    top: 0;
    border-top: 0;
    font-weight: bold;
    > div {
      display: flex;
    }
    .dynamic-field {
      width: 200px;
    }
  }
  .table-row {
    .table-row-draggable ~ .table-row-draggable {
      border-bottom: 2px solid #e3e6ea;
      margin-top: 0;
    }
  }
  .table-row-draggable {
    display: flex;
    margin-top: 25px;
    padding-bottom: 25px;
    &.table-row-compact {
      margin-top: 10px;
      padding-bottom: 10px;
      .dynamic-field {
        width: 120px;
        min-width: 120px;
        font-size: 16px;
      }
      &.table-header {
        font-size: 20px;
        .dynamic-field {
          font-size: inherit;
        }
      }
    }
    .count-cell {
      width: 48px;
      padding: 12px 0;
    }
    .check-cell {
      input {
        width: 20px;
        height: 20px;
      }
    }
    & > div {
      margin: 0px 10px;
      // padding: 0px 10px;
      display: flex;
      align-items: center;
      vertical-align: middle;
    }

    .dynamic-field {
      width: 200px;
    }
  }
  .dynamic-field-link {
    color: $color-success;
    cursor: pointer;
  }
  .copy-row {
    width: 40px;
  }
  .delete-row {
    width: 40px;
  }
}

.table-form-controls {
  padding: 24px 5px 28px 5px;

  .table-form-title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #7d829e;
    font-size: 20px;
    font-weight: 700;
  }
  .inputs-container {
    .form-label {
      height: 40px;
      white-space: nowrap;
      margin-right: 15px;
    }
    .form-input {
      max-width: 150px;
    }
  }
}
