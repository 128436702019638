.communication-list {
  max-height: 100%;
  height: 100%;

  .communication-header {
    padding: 0;
    justify-content: center;
    text-transform: capitalize;
  }

  .communication-body {
    flex: 1 1 auto;
    margin: 0;
  }

  .communication-footer {
    border-top: 1px solid #f0f0f6;
    justify-content: flex-end;
    padding: 40px;
    display: flex;
  }

  .communication-empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 30px 20px;

    .empty-message {
      margin-bottom: 20px;
      text-align: center;
      word-break: break-word;
    }
  }

  .dropdown-container {
    justify-content: space-between;
    padding-left: 20px;
  }
}
