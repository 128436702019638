.calculator-modal {
  max-width: 875px;
  width: 100%;

  .title-badge {
    min-width: 120px;
    height: 30px;
    padding: 0 10px;
    background-color: #506479;
    font-size: 16px;
    margin-left: 40px;
    text-align: center;
    line-height: 30px;
    font-weight: normal;
    overflow: hidden;
  }
}
