.slider-wrapper {
  .range-container {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
  }

  .slider-input {
    .rc-slider-rail,
    .rc-slider-step {
      height: 10px;
    }

    .rc-slider-track {
      height: 10px;
      background-color: #7d829e;
    }

    .rc-slider-handle {
      width: 20px;
      height: 20px;
      background-color: #f8f8fc;
    }
  }
}
