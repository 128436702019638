.receipt-modal {
  max-width: 1320px;
  width: 100%;

  .row {
    margin: 0;

    .form-column {
      padding-right: 20px;
      padding-left: 0px;
    }
  }

  .col-form {
    padding-right: 20px;
    padding-left: 0px;
  }

  .table-form-controls {
    padding: 24px 5px 28px 0px;

    .table-form-title {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      color: #7d829e;
      font-size: 20px;
      font-weight: 700;
    }
  }

  .receipts-title {
    height: 60px;
    font-weight: bold;
    line-height: 60px;
  }
}
