@import "../../../../styles/variables.scss";
.text-input {
  padding: 0 20px;
  &.form-input-compact {
    height: $compact-input-height;
    font-size: $compact-input-font-size;
    padding: 0 10px;
  }
}

.textarea-input {
  border: 1px solid #d1d2de;
  background-color: #f8f8fc;
  padding: 20px;
  &.form-input-compact {
    // height: $compact-input-height;
    font-size: $compact-input-font-size;
    padding: 5px 10px;
  }
}
