.edit-details-modal {
  .avatar-detail {
    padding: 80px 0 40px 0;
    display: flex;
    justify-content: center;
  }

  .rich-editor-wrapper {
    .rdw-editor-main {
      min-height: 250px;
    }
  }
}
