@import "../../../styles/common";
@import "../../../styles/variables.scss";

.quote-costing-categories {
  max-height: 100%;

  .quote-costing-list {
    overflow-y: auto;
    min-height: 80px;
    max-height: calc(100vh - 500px);
    .quote-costing-list-item {
      .item-name {
        .icon-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          height: 100%;
          width: 25px;
        }
        display: flex;
        align-items: center;
        i {
          margin-right: 5px;
        }
      }
      .complete-icon {
        font-size: 19px;
        color: $color-success;
      }
      .incomplete-icon {
        font-size: 19px;
        color: $color-error;
      }
      .hidden-icon {
        font-size: 19px;
        color: $color-primary-light;
      }
      @extend %category-item;
      box-shadow: 0 1px 0 0 #d1d2de;
      margin-bottom: 1px;
      padding: 0 5px;
      &:hover {
        background-color: #fcf8f8;
      }

      .right-side {
        display: flex;
        align-items: center;

        .item-total {
          margin-right: 10px;
          font-size: 16px;
        }
        .copy-action,
        .remove-action {
          height: 40px;
          width: 24px;
          display: flex;
          justify-content: center;
          align-items: center;
          user-select: none;
          cursor: pointer;

          i {
            font-size: 22px;
          }
        }
        .copy-action {
          i {
            font-size: 20px;
          }
        }
      }
    }
  }

  .costing-category-selected {
    background-color: #fcf8f8;
  }

  .divider {
    border: 1px solid #d1d2de;
    margin: 40.5px 0;
  }

  .add-category {
    width: 100%;
    margin-bottom: 20px;
  }
  .searchInput {
    width: 120px;
  }
}
