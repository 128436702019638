@import "../../../styles/variables.scss";

.templates-sections {
  color: $color-primary;
  .templates-section {
    .templates-section-items {
      display: flex;
      flex-wrap: wrap;
    }

    .templates-section-item {
      width: 350px;
      margin: 10px 20px 20px 0;
      padding: 40px 80px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: $color-primary;
      background-color: $color-light;
      border: 1px solid $border-color-primary;
      cursor: pointer;
      transition: all 0.1s linear;

      .templates-section-icon {
        font-size: 80px;
      }
      &:hover {
        transform: scale(1.01);
        background-color: $border-color-light;
        text-decoration: none;
      }
    }
  }
}
