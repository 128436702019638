.authentication-card-container {
  &.sign-in-card {
    .remember-me {
      color: #7d829e;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: -0.48px;
      white-space: nowrap;
      display: flex;
      align-items: center;
      height: 60px;
      width: 190px;

      .form-check-input {
        margin-top: 0.1rem;
      }
    }
    .sign-in-submit {
      flex-direction: row-reverse;
    }

    .sign-in-button {
      margin-left: 20px;
    }

    .sign-up-button {
      margin-left: 20px;
      text-decoration: none;
      .button {
        width: auto;
        white-space: nowrap;
      }
    }
    @media (max-width: 920px) {
      .sign-in-other {
        margin-top: 40px;
        flex-direction: column;
        width: 100%;
      }
    }
  }
}
