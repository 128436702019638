@import "../../../../styles/variables.scss";
.select-pages-card {
  max-height: 100%;

  &.inactive {
    opacity: 0.5;
    pointer-events: none;
  }

  .body {
    .pages-list {
      padding: 0;
      margin: 0;
      display: flex;
      list-style: none;
      flex-wrap: wrap;
      max-width: 100%;

      .page-item {
        position: relative;
        display: flex;
        justify-content: center;
        padding: 0;
        margin: 0;
        width: 315px;
        // max-width: 100%;
        height: 260px;
        margin-right: 20px;
        margin-bottom: 20px;
        background: #ffffff;
        border: 2px solid #d1d2de;
        font-size: 14px;
        color: #7d829e;
        letter-spacing: -0.34px;
        overflow: hidden;
        cursor: pointer;
        user-select: none;

        &.is-selected {
          border: 2px solid #3fbb64;
        }

        &.placeholder {
          align-items: center;
          i {
            font-size: 96px;
          }
        }

        .selection-mark {
          position: absolute;
          bottom: 0;
          right: 0;
          width: 40px;
          height: 40px;
          background: #3fbb64;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #ffffff;
          z-index: 1;

          i {
            font-size: 30px;
          }
        }

        .page-placeholder {
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;

          i {
            font-size: 96px;
          }

          .rotate-icons {
            margin-top: 10px;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            button {
              outline: none;
              border: none;
              background: none;
            }
            i {
              font-size: 28px;
              &:hover {
                border-radius: 50%;
                background: $text-color-placeholder;
              }
            }
          }
        }

        .page-number {
          position: absolute;
          height: 36px;
          font-size: 14px;
          color: #7d829e;
          letter-spacing: -0.34px;
          display: flex;
          align-items: center;
          bottom: 0;
          left: 0;
          background-color: #fff;
          padding: 10px;
        }
      }
    }
  }
  .card-title {
    margin: 20px 20px 0px 20px;
    font-size: 20px;
    line-height: 1.15;
    text-align: center;
  }
}
