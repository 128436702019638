.plans-card {
  height: 100%;
  min-height: 100%;
  .header {
    display: flex;
    justify-content: space-between;

    .page-selector {
      display: flex;
      height: 100%;

      .dropdown {
        &.show {
          .selector-button {
            background-color: #506479;
          }
        }

        .selector-button {
          width: 140px;
          font-family: Helvetica, sans-serif;
          font-size: 18px;
          color: #feffff;
          letter-spacing: -0.43px;
          text-align: center;
          font-weight: 600;

          &:focus {
            outline: none;
            box-shadow: none;
          }

          &::after {
            display: none;
          }
        }

        .dropdown-menu {
          border: none;
          border-radius: 0;
          padding: 0;
          margin: 0;
          width: 140px;
          min-width: 140px;
          background: #314961;
          box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.3);

          .dropdown-item {
            margin: 0;
            height: 60px;
            width: 140px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #ffffff;
            user-select: none;
            font-weight: 600;
            font-size: 18px;

            &:hover {
              background-color: #506479;
            }

            &.active {
              background-color: #506479;
            }
          }
        }
      }

      .selector-button {
        height: 100%;
        min-width: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        background-color: #314961;
        border: none;
        border-radius: 0;

        &:hover {
          background-color: #506479;
        }

        &.disabled {
          cursor: not-allowed;

          &:hover {
            background-color: transparent;
          }

          i {
            opacity: 0.5;
          }
        }

        i {
          font-size: 30px;
        }
      }
    }
  }

  .body {
    flex-grow: 1;
    margin: 0;
    padding: 0;
  }
}
