.channel-list {
  max-height: calc(100vh - 140px);
  height: 100%;
  overflow: auto;
  .channel-header {
    padding: 0;
    justify-content: center;
  }

  .channel-main-list {
    &--open {
      .channel-item-list {
        max-height: 100% !important;
      }
    }

    .channel-main-item {
      padding: 1rem 1.25rem;
      border-bottom: 1px solid #dee2e6;
      align-items: center;
      display: flex;
      cursor: pointer;

      &--title {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    .material-icons {
      font-size: 32px;
      line-height: 0;
    }

    span {
      margin-left: 0.5rem;
      font-size: 0.8em;
      white-space: nowrap;
    }

    .channel-item-list {
      max-height: 0px;
      overflow: hidden;
      transition: max-height 0.25s ease-in-out;
      padding: 0;
      margin: 0;

      &--selected {
        background-color: #f8f8fc;
      }

      .channel-item {
        padding: 1rem 1.25rem;
        border-bottom: 1px solid lighten(#dee2e6, 5%);
        cursor: pointer;

        &-top-content {
          display: flex;
          align-items: center;
        }

        &--name {
          font-size: 18px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        &--date {
          font-size: 14px;
          text-align: end;
        }

        &-bottom-content {
          margin-top: 10px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        &--message-text {
          font-size: 20px;

          &-disabled {
            color: #d1d2de;
          }
        }
      }
    }
  }
}
