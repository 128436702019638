@import "../../../styles/variables.scss";

.job-costings-modal {
  max-width: 680px;
  width: 100%;

  .form-input-label {
    font-size: 18px;
  }
  .table-responsive {
    max-height: calc(100vh - 490px);
    overflow-y: visible;
  }
  .footer,
  .modal-body {
    padding: 20px 40px;
  }

  .add-button {
    height: 40px;
    background-color: $text-color-primary;
  }

  .search-input {
    padding: 0px 10px;
    padding-right: 30px;
    width: 580px;
    color: $color-primary-light;
    font-size: 16px;
    background-color: $color-light;
    border: 1px solid $border-color-primary;
    &::placeholder {
      color: $color-primary-light;
    }
  }
  .inputSearch-container {
    margin-left: 10px;
    margin-bottom: 10px;
    .inputSearch {
      height: 40px;
      &::placeholder {
        font-weight: 400;
        font-size: 16px;
      }
    }
    .cancel-icon-container {
      color: $color-primary-light;
      right: 15px;
    }
    .search-icon {
      color: $color-primary-light;
      right: 15px;
    }
  }
}
