.contacts-supplier {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;

  .details-card {
    max-height: 100%;
    width: 100%;
  }

  .table-card {
    margin: 0;
  }

  .btn-supper-large {
    min-width: 230px;
  }

  .certification-wrapper {
    margin-top: 48px;
    .table-card {
      .body {
        overflow: visible;
      }
    }
  }
}
