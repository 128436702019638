.message-file {
  cursor: initial;
  height: 90px;
  border-radius: 3px;
  padding: 0 20px;
  background-color: #f8f8fc;
  border: 1px solid #f0f0f6;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &-name {
    font-size: 24px;
    letter-spacing: -0.58px;
    margin-bottom: 10px;
  }

  &-size {
    color: #213242;
  }

  &-icon {
    user-select: none;
    padding-right: 15px;
    color: #7d829e;

    .material-icons {
      font-size: 40px;
    }
  }

  .download-icon {
    color: #7d829e;
  }

  &-download {
    cursor: pointer;

    .material-icons {
      font-size: 30px;
    }
  }
}
