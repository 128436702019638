.user-details-modal {
  max-width: 540px;
  width: 100%;
  padding: 0;
  .detailsContainer {
    width: 80%;
  }
  .cardBody {
    flex-direction: column;
    align-items: center;
    width: 500px;
  }
  .profile-avatar {
    margin-bottom: 20px;
  }
  .dashBoardCardStyles {
    box-shadow: none;
  }
  .loaderContainer {
    width: 540px;
    height: calc(100vh - 60px);
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
}
