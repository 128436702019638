.client-input-container {
  display: flex;

  .add-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #7d829e;
    width: 60px;
    height: 60px;
    min-width: 60px;

    i {
      margin: 0;
      color: #ffffff;
      font-size: 30px;
    }
  }
}
