.assemblies-table-container {
  .table-card {
    height: 100%;
    .dashboard-card {
      height: 100%;

      .footer {
        .btn-super-large {
          width: 200px;
        }
      }
    }
  }
}
