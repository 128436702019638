@import "../../../styles/variables.scss";

.select-document-modal {
  max-width: 680px;
  width: 100%;
  .dashboard-card {
    .body {
      padding: 0 30px;
      margin: 0px;
    }
  }
  .header-container {
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .breadcrumbs-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  .search-input {
    padding: 0px 10px;
    padding-right: 30px;
    width: 175px;
    color: $color-primary-light;
    font-size: 20px;
    background-color: $text-color-light;
    border: 2px solid $border-color-primary;
    &::placeholder {
      color: $color-primary-light;
    }
  }
  .inputSearch-container {
    margin-left: 15px;
    .cancel-icon-container {
      color: $color-primary-light;
      right: 5px;
    }
    .search-icon {
      color: $color-primary-light;
    }
  }
  .breadcrumb-buttons {
    height: 40px;
    background-color: $text-color-light;
    color: $color-primary;
    padding: 0;
    margin: 0;
    min-width: auto;

    &:active {
      border: none !important;
      background-color: transparent !important;
    }

    &:focus {
      box-shadow: none !important;
      background-color: transparent !important;
    }
  }

  .breadcrumb-delimiter {
    padding: 0 8px;
    cursor: default;
  }
  .table-card .table-responsive {
    max-height: calc(100vh - 345px);
    overflow-y: visible;
  }
  .modal-body {
    padding-bottom: 0px;
  }
  .table-row {
    cursor: pointer;
  }
  .dashboard-card {
    box-shadow: none;
    .body {
      padding: 0px 0px;
    }
  }
  .new-folder-button {
    .button {
      width: 150px;
    }
  }
  .empty-placeholder {
    width: 100%;
    margin-top: 72px;
    align-items: center;
  }
  .table-name {
    width: 200px;
  }
}
