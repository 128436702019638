.create-category-modal {
  max-width: 680px;
  width: 100%;

  .file-input {
    height: 150px;
  }
  .uploader {
    padding: 34px 10px 0 10px;

    .table-responsive {
      margin-top: 30px;
    }
  }
  &.update-category-modal {
    width: 100%;
    max-width: 1380px;
  }
  .overflow-auto {
    overflow: visible !important;
  }
}
