.loader {
  .spinner-grow {
    color: #314961;
    width: 45px;
    height: 45px;
  }
}
.spinner-border {
  color: #314961;
  &-accent {
    color: #3fba63;
  }
}
