.proposal {
  border: 1px solid #d1d2de;
  margin-bottom: 1rem;
  height: calc(100% - 1rem);

  .proposal-header {
    border-bottom: 1px solid #d1d2de;
    background-color: #f8f8fc;
    height: 60px;

    align-items: center;
    justify-content: center;
    display: flex;
    font-weight: bold;
  }
  .proposal-body {
    padding: 15px;
  }
}
.quote-request-modal {
  .conversation .conversation-messages {
    padding: 0;
  }
}
