.list-cards {
  width: 270px;
  background-color: #f4f5f7;
  margin-right: 15px;
  height: 100%;
  .title-list {
    height: 68px;
    padding: 10px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
    text-transform: uppercase;
    color: #7d829e;
    font-family: Helvetica, sans-serif;
    font-size: 16px;
    font-weight: bold;
    line-height: 24px;
    letter-spacing: -0.48px;

    position: relative;

    &:hover .more {
      opacity: 1;
    }
    .more {
      opacity: 0;
      position: absolute;
      right: 0;
      bottom: 0;
      z-index: 1;
      transition: opacity 0.1s linear;
      .button {
        min-width: 60px;
      }
    }
    .toggleClass {
      background-color: transparent;
    }
    .list-info {
      font-weight: 400;
    }
  }

  .container-cards {
    height: calc(100% - 80px);
    padding: 0 1rem;
    transition: background-color 0.4s ease;
  }

  .card-container {
    margin: 0.5rem 0;
    height: 100%;
  }
}
