.button-select-input {
  display: flex;
  justify-content: space-around;

  .button {
    width: 100%;
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }
  }
  @media (max-width: 920px) {
    .button {
      font-size: 16px;
    }
  }
}
