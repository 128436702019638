@import "../../styles/variables";

.Toastify__toast {
  padding: 0;
  box-shadow: 0 1px 10px 10px rgba(0, 0, 0, 0.1),
    0 2px 15px 0 rgba(0, 0, 0, 0.05);
}

.Toastify__toast-container {
  width: 410px;
}

.toast {
  max-width: 410px;
}

.Toastify__toast-container--top-center {
  top: 0;
}

.message-notification {
  .conatiner {
    padding: 5px;
    display: flex;
    align-items: center;
  }

  .avatar-container {
    margin: 10px 0 10px 10px;
  }

  .notification-body {
    width: 100%;
  }

  .content {
    max-width: 280px;
    margin-bottom: 15px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3; /* start showing ellipsis when 3rd line is reached */
    white-space: pre-wrap; /* let the text wrap preserving spaces */
  }

  .link-container {
    text-align: right;

    button {
      background: none;
      border: none;
      color: $text-color-primary;
      font-weight: bold;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .title {
    width: 300px;
    display: flex;
    justify-content: space-between;
    color: $color-primary-dark;
    font-weight: 700;
    .close {
      font-size: 25px;
    }
  }

  .body {
    min-height: 60px;
    font-size: 18px;
    // background-color: $color-success;
    color: black;
  }
}
