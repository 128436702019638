.order-card {
  .delivery {
    .delivery-body {
      padding-top: 20px;
    }

    .delivery-title {
      height: 60px;
      font-weight: bold;
      line-height: 60px;
    }
  }
}
