@import "../../../styles/variables.scss";

.note-item {
  border-bottom: 1px solid $border-color-light;
  &:last-child {
    border-bottom: 0;
  }
  &--description {
    white-space: pre-wrap;
    text-align: justify;
  }
  &--creation {
    flex-direction: row;
    display: flex;
    .field-text {
      font-size: 16px;
    }
  }
  &--attachments {
    &--file {
      text-decoration: none !important;
    }
    .material-icons {
      font-size: 18px;
    }
    .field-text {
      font-size: 14px;
    }
  }
  .material-icons {
    font-size: 20px;
    color: $color-primary;
  }
}
