.channels-list {
  .channels-header {
    padding: 0;
    justify-content: center;
    text-transform: capitalize;
  }

  .channels-body {
    padding: 0;
    margin: 0;
  }
}
