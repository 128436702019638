.add-certification-modal {
  max-width: 720px;
  width: 100%;

  .uploader-wrapper {
    margin-top: 20px;
    max-width: 500px;

    .uploader {
      padding: 34px 10px 0 10px;
    }

    .dashboard-card {
      .body {
        padding: 0 30px;
        margin: 0px;
      }
    }
  }
}
