@import "../../../styles/variables";

.dashboard-header {
  width: 100%;
  height: $header-height;
  background-color: #314961;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);

  .title-container {
    display: flex;
    height: 100%;

    .heading {
      display: flex;
      width: 100%;
      height: 100%;
      align-items: center;

      padding: 15px;
      padding-left: 40px;

      color: #ffffff;
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: -0.48px;
    }

    .header-title {
      display: flex;
      justify-content: center;
      align-items: center;
      max-width: 340px;
      height: 100%;
      background-color: #213242;
      color: darken(#ffffff, 50);
      font-weight: bold;
      font-size: 18px;
      line-height: 22px;

      margin-right: 0;
      margin-left: auto;

      i {
        font-size: 30px;
        margin-right: 5px;
      }
      .dropdown-menu {
        &.show {
          width: 300px;
          overflow-x: hidden;
        }
        .dashboard-dropdown-item {
          text-overflow: ellipsis;
          width: 300px;
        }
      }
    }

    .help {
      display: flex;
      background-color: $color-success;
      width: 50px;
      height: 100%;

      .help-button {
        width: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
    }
  }
}
