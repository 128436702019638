@import "../../styles/variables.scss";

.add-column-container {
  width: 270px;
  background-color: #f4f5f7;
  margin-right: 15px;
  height: 100%;

  .input-style {
    padding: 5px 10px;
    width: 100%;
    color: $color-primary-light;
    font-weight: bold;
    border: 2px solid #3fbb64;
    outline: #3fbb64;
    border-radius: 3px;
  }
  .container-icon {
    margin: 5px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    background-color: #f4f5f7;
    border: none;
    outline: none;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
      rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
    cursor: pointer;
    & i {
      display: block;
      color: #7d829e;
      font-size: 30px;
      transition: all 0.1s linear;
    }
    & i:hover {
      background-color: rgba(9, 30, 66, 0.08);
      transform: scale(1.05);
    }
    & i:active {
      background-color: rgba(9, 30, 66, 0.15);
    }
    &:disabled {
      opacity: 0.5;
      cursor: default;
      background-color: rgba(9, 30, 66, 0.08);
    }
  }
  .container-icons {
    display: flex;
    justify-content: end;
  }
}
.insideColumn {
  width: 100%;

  .wrapper {
    padding-left: 5px;
    display: flex;
    position: absolute;
    z-index: 2;
    left: 0px;
    top: 50%;
    transform: translateY(-50%);
  }
}
