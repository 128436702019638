@import "../../../styles/variables.scss";

.job-costing-container {
  .toggle-view {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $text-color-primary-dark;
    width: 60px;
    height: 60px;
    border: none;
    outline: none;
    border-radius: 0;
    i {
      font-size: 32px;
    }
    &:focus {
      box-shadow: none !important;
    }
  }
  .allowances-view {
    margin-right: 10px;
  }
  .active-view {
    border: 4px solid $color-success;
  }
  .section-title {
    color: $color-primary;
    font-weight: bold;
    font-size: 30px;
  }
  .category-table {
    .table-row--total {
      border-top: 3px solid $border-color-primary !important;
    }
    .table-row {
      height: 60px;
    }
    .body {
      margin-top: 0px;
    }
  }

  .job-costing-items {
    .row-actions .action {
      margin-right: 0 !important;
      svg {
        width: 24px;
        height: 24px;
      }
    }
  }
}
