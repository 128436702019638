.authentication-card-container {
  max-width: 920px;
  min-height: 430px;
  margin-bottom: 50px;

  .field-text {
    white-space: nowrap;
  }

  @media (max-width: 920px) {
    height: 100%;
    width: 100%;
  }
  .authentication-logo {
    margin: 40px;
    justify-content: center;
    display: flex;
  }
  .authentication-card {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: column;
    height: auto;
    background-color: #fff;

    .header {
      min-height: 100px;
      background-color: #314961;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 40px;
      h1 {
        font-size: 2rem;
        color: #fff;
        margin-right: 30px;
      }

      .logo {
        user-select: none;
        display: block;
        height: 40px;
        width: 272px;
      }
    }

    .body {
      display: flex;
      padding: 20px 40px;
      height: 100%;
    }

    .footer {
      width: 100%;
      padding: 20px 40px;
      padding-bottom: 50px;
    }
  }
}
