.upload-files-modal {
  max-width: 680px;
  width: 100%;

  .uploader {
    padding: 34px 10px 0 10px;
  }

  .dashboard-card {
    .body {
      padding: 0 30px;
      margin: 0px;
    }
  }
}
