@import "../../../styles/variables.scss";

.dashboard-action-header {
  .export-button {
    margin-right: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $text-color-primary-dark;
    width: 60px;
    height: 60px;
    border: none;
    outline: none;
    border-radius: 0;
  }
}
