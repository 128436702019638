.calendar {
  font-family: Helvetica, sans-serif;

  .gantt-schedule-timeline-calendar__chart-calendar-date--level-0 {
    text-transform: uppercase;
    font-size: 20px;
    color: #7d829e;
    letter-spacing: -0.48px;
    height: 40px;
  }

  .gantt-schedule-timeline-calendar {
    background: transparent;
  }

  .gantt-schedule-timeline-calendar__chart-calendar {
    background-color: transparent;
  }

  .gantt-schedule-timeline-calendar__chart-calendar-dates--level-1 {
    border: none;
    padding-bottom: 20px;
    border-bottom: 1px solid #7d829e;
  }

  .gantt-schedule-timeline-calendar__list-column-header-resizer-dots {
    margin-bottom: 20px;
    padding-top: 5px;
    height: 60px;
    background-color: #f8f8fc;
    display: none;
  }

  .gantt-schedule-timeline-calendar__chart-calendar-date-content--month {
    border-left: none;
    border-right: none;
    font-weight: bold;
    font-size: 20px;
    color: #7d829e;
    letter-spacing: -0.48px;
  }

  .gantt-schedule-timeline-calendar__chart-timeline-grid-row-block,
  .gantt-schedule-timeline-calendar__list-column-row {
    border-right: none;
  }

  .gantt-schedule-timeline-calendar__list-column-header {
    background: transparent;
  }

  .gantt-schedule-timeline-calendar__list-column-header-resizer {
    align-items: flex-end;
    pointer-events: none;

    .gantt-schedule-timeline-calendar__list-column-header-resizer-container {
      height: 60px;
      margin-bottom: 20px;
      background-color: #f8f8fc;
    }
  }

  .gantt-schedule-timeline-calendar__list-column-header-resizer-container {
    font-weight: 700;
  }

  .gantt-schedule-timeline-calendar__list-column-header {
    border-bottom: 1px solid #7d829e;
  }

  .gantt-schedule-timeline-calendar__list-column-header-resizer-container,
  .gantt-schedule-timeline-calendar__list-column-row-content {
    display: flex;
    align-items: center;
    color: #7d829e;
    font-family: Helvetica, sans-serif;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.48px;
  }

  .gantt-schedule-timeline-calendar__chart-calendar-date--level-1 {
    user-select: none;
    text-align: center;
    background-color: #ffffff;
    border-right: none;
    height: 60px;
    box-shadow: unset;

    .gstc-current {
      background-color: #f8f8fc;
    }

    .gantt-schedule-timeline-calendar__chart-calendar-date-content {
      display: flex;
      flex-flow: column-reverse;
      width: 60px;
      background-color: #f8f8fc;
    }

    .gantt-schedule-timeline-calendar__chart-calendar-date-content--year {
      height: 60px;
      line-height: 60px;
    }

    .gstc-date-bold,
    .gstc-date-top {
      display: flex;
      align-items: center;
      color: #304256;
      letter-spacing: -0.48px;
      font-size: 20px;
      height: 30px;
      justify-content: center;
      text-align: center;
    }

    .gstc-date-thin,
    .gstc-date-small {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #898da9;
      font-weight: bold;
      letter-spacing: -0.34px;
      height: 30px;
      font-size: 14px;
      text-align: center;
    }

    .gstc-date-month-level-1 {
      margin: 0;

      .gstc-date-top {
        font-size: 12px;
      }
    }

    .gstc-date-vertical {
      .gstc-date-top {
        font-size: 14px;
      }
    }

    .gstc-date-small {
      font-size: 12px;
    }
  }

  .gantt-schedule-timeline-calendar__chart-timeline-items-row-item {
    height: calc(var(--row-height) - 30px);
    line-height: calc(var(--row-height) - 30px);
    margin-top: 15px;
    padding: 0;
    border-radius: 0;
    background-color: #dedfe8;
  }

  .gantt-schedule-timeline-calendar__list-column-header {
    .gantt-schedule-timeline-calendar__list-column-row-expander {
      display: none;
    }
  }

  .gantt-schedule-timeline-calendar__list-column-row-expander-toggle-child {
    width: 10px;
    height: 10px;
  }

  .gantt-schedule-timeline-calendar__chart-timeline-items-row-item-label {
    margin: 0;
  }

  .gantt-schedule-timeline-calendar__list-toggle {
    display: none;
  }

  .calendar-actions {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    .action {
      cursor: pointer;
      font-size: 28px;
      padding-right: 5px;
      padding-left: 5px;
      // border-right: 1px solid #b3bcc5;
      text-overflow: clip;
      width: 38px;
      line-height: 38px;

      &.empty {
        width: 28px;
      }

      &:last-child {
        // padding-right: 0;
        border-right: 0;
      }
    }
  }

  .child-row {
    background-color: #f8f8fc;
  }
  // new styles
  .gstc__chart-timeline-items-row-item-label {
    margin: 0;
    mask-image: none;
    border-radius: 15px;
    overflow: visible;
  }
  .gstc__chart-timeline-grid-row-cell {
    pointer-events: all;
    flex-grow: 0;
    flex-shrink: 0;
    vertical-align: middle;
    overflow: hidden;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    touch-action: none;
    height: 100%;
  }
  .gstc__chart-timeline-grid-row-cell--weekend {
    background: rgba(49, 73, 97, 0.1);
  }
  .gstc__list-column-row-content {
    &.progress-select {
      display: flex;
      align-items: center;
    }
    .avatar-content {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
  }

  .gstc__list-column-header-resizer-container--action {
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .row-child {
    .gstc__list-column-row-expander {
      display: none;
    }
  }
  .row-parent {
    .gstc__list-column-row-expander {
      width: 30px;
    }
    .gstc__list-column-row-expander-toggle {
      padding-left: 5px;
      width: 25px;
    }
  }
  .add-category {
    align-items: center;
    display: flex;
  }
}
.calendar-item {
  position: relative;
  width: 100%;
  .calendar-item-name {
    position: absolute;
    display: block;
    margin-left: 20px;
    font-size: 14px;
    line-height: 30px;
    width: 100%;
    height: 100%;
    // text-align: center;
    color: #3e404f;
    left: 100%;
  }
}
.gstc__chart-timeline-items-row-item.gstc__selected {
  .calendar-item {
    .calendar-item-name {
      left: calc(100% + 18px);
    }
  }
}
.calendar-progress {
  background-color: rgba(255, 255, 255, 0.6);
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23ffffff66' fill-opacity='1' fill-rule='evenodd'%3E%3Cpath d='M0 40L40 0H20L0 20M40 40V20L20 40'/%3E%3C/g%3E%3C/svg%3E");
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  justify-content: flex-start;
  font-size: 14px;
  font-weight: bold;
  color: black;
  display: flex;
  height: 100%;
  align-items: center;
  .calendar-progress-percent {
    margin-left: 10px;
  }
}
