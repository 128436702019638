@import "./variables.scss";

.field-text {
  color: #7d829e;
  font-family: Helvetica, sans-serif;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.48px;

  &.medium {
    font-weight: bolder;
  }

  &--underlined {
    border-bottom: 1px solid #7d829e;
  }
}

.success-text {
  color: #3fbb64;
}

.no-wrap {
  white-space: nowrap;
}

.cursor-pointer {
  cursor: pointer;
  user-select: none;
}

a {
  font-size: 20px;
}
h4 {
  color: $text-color-primary-dark;
}
