@import "src/styles/common";

.category-select-card {
  .body {
    overflow-y: auto;
    min-height: 80px;
    max-height: calc(100vh - 270px);
  }
  .heightWithTotal {
    max-height: calc(100vh - 385px);
  }
  .list {
    .list-checkbox {
      width: 20px;
      height: 20px;
    }
    .list-item {
      @extend %category-item;
      height: auto;
      min-height: 40px;
      box-shadow: 0 1px 0 0 #d1d2de;
      margin-bottom: 1px;

      &:hover {
        background-color: #fcf8f8;
      }
      .list-item-label {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }

  .item-selected {
    background-color: #fcf8f8;
  }
  .total-container {
    padding: 0px 20px 20px 20px;
  }
  .divider {
    border: 1px solid #d1d2de;
    margin: 20px;
  }
}
