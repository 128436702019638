.teammate-item {
  padding: 15px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .teammate-info {
    flex: 1 1 auto;
    padding-right: 40px;
    min-width: 0;
  }

  .user-name {
    font-size: 16px;
  }

  .remove {
    color: #7d829e;
    cursor: pointer;
  }

  .teammate-avatar {
    margin-right: 15px;
  }

  .teammate-actions {
    .btn {
      background-color: white;
      border: none;
    }
  }
}
