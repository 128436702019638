@import "../../../../../styles/variables.scss";

.ra-list {
  width: auto;
  .ra {
    background-color: $color-light;
    padding: 5px 10px;
    border: 1px solid $border-color-primary;
    border-bottom: 0;
    &:last-child {
      border-bottom: 1px solid $border-color-primary;
    }
    .ra-name {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .ra-remove {
      cursor: pointer;
      i {
        font-size: 20px;
      }
    }
    .attachment-item {
      margin-right: 10px;
      .attachment-item-file {
        height: 100px;
        width: 100px;
        cursor: default;
        .attachment-icon {
          i {
            font-size: 60px;
            color: $color-primary;
            transition: transform 0.1s linear;
          }
        }
      }
      .attachment-item-image {
        cursor: default;
      }
    }
  }
}
.file-dropzone {
  .dropzone-container {
    padding: 30px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $color-light;
    border: 2px dashed $border-color-primary;
    cursor: pointer;
    transition: background-color 0.2s linear;

    i {
      font-size: 44px;
    }
    &:active {
      background-color: $color-primary;
      color: $color-light;
    }
  }
}
