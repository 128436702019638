@import "../../styles/variables";
.Toastify__toast {
  padding: 0;
}

.Toastify__toast-container {
  width: 400px;
}

.toast {
  max-width: 400px;
}

.Toastify__toast-container--top-center {
  top: 0;
}

.notification {
  .title {
    display: flex;
    justify-content: space-between;
    background-color: $color-success;
    color: #fff;

    &.error {
      background-color: #dc3545;
    }
  }

  .body {
    min-height: 60px;
    font-size: 18px;
    background-color: $color-success;
    color: #fff;

    &.error {
      background-color: #dc3545;
    }
  }
}
