@import "../../../styles/variables.scss";

.quote-costing-container {
  .toggle-view {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $text-color-primary-dark;
    width: 60px;
    height: 60px;
    border: none;
    outline: none;
    border-radius: 0;
    i {
      font-size: 32px;
    }
  }
  .category-table {
    .table-row--total {
      border-top: 3px solid $border-color-primary !important;
    }
    .table-row {
      height: 60px;
    }
    .body {
      margin-top: 0px;
    }
  }

  .table {
    .table-body {
      .table-row {
        .row-actions {
          .badge-warning {
            right: 0px;
            top: 2px;
          }
        }
      }
    }
  }
}
