@import "../../styles/variables";
.react-joyride__tooltip {
  border-radius: 0;
  h4 {
    color: #314961;
    font-size: 24px;
  }
  .tooltip-footer {
    display: flex;
    justify-content: flex-end;
    .button {
      height: 45px;
    }
  }
}
