.colour-picker {
  &.show {
    .picker-toggle {
      background-color: #f8f8fc;
      border: 1px solid #d1d2de;
    }
  }

  .picker-toggle {
    height: 60px;
    width: 100%;
    border-radius: 0;
    background-color: #f8f8fc;
    border: 1px solid #d1d2de;
    display: flex;
    justify-content: space-between;
    align-items: center;

    i {
      color: #7d829e;
      font-size: 30px;
    }

    &:focus {
      box-shadow: none !important;
      outline: none;
    }

    &:active {
      background-color: #f8f8fc !important;
    }

    &::after {
      display: none;
    }

    .picker-info {
      display: flex;
      align-items: center;

      .selected-color {
        width: 30px;
        height: 30px;
        border: 2px solid #7d829e;
        border-radius: 50%;
        margin-right: 20px;
      }

      .placeholder {
        font-family: Helvetica;
        font-size: 20px;
        color: #d1d2de;
        letter-spacing: -0.48px;
      }
    }
  }

  .picker-menu {
    background: transparent;
    border: none;
    width: 200px;

    .sketch-picker {
      // width: calc(100% - 40px) !important;
      background: #f8f8fc !important;
      border: 1px solid #d1d2de !important;
      border-radius: 0 !important;
      padding: 20px !important;
      box-shadow: none !important;
    }
  }
}
