@import "../../../../styles/variables.scss";
.site-diary-template-modal {
  width: 100%;
  max-width: 1380px;

  .table-card-responsive {
    overflow: visible !important;
  }
  .modal-footer {
    border-top: 1px solid #d1d2de;
  }
  .form-input-label {
    height: 50px;
  }
  .sdt-row {
    display: flex;
    width: 100%;
    border-top: 1px solid $border-color-primary;
    margin-top: 1rem;
    align-items: center;
    .sdt-row-count {
      width: 48px;
    }
    .sdt-row-fields {
      flex: 1;
    }
    .sdt-row-remove {
      width: 48px;
      display: flex;
      justify-content: flex-end;
      .remove-action {
        cursor: pointer;
        width: 24px;
        height: 24px;
      }
    }
  }
  .sdt-sub-row {
    width: 100%;
    padding: 0 48px;
  }
}
