@import "../../styles/variables.scss";
.rbc-calendar {
  height: calc(100vh - #{$header-height} - 160px);
  min-height: 800px;
  .rbc-event-content {
    font-size: 14px;
  }
}
.rbc-row-segment {
  min-height: 48px;
}
.event-jobname {
  font-size: 12px;
  color: #fff;
  padding: 5px 5px 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 200px;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.3);
}
.event-purchaseorder {
  background-color: $color-primary;
  .rbc-event,
  .rbc-day-slot .rbc-background-event {
    background-color: $color-primary;
  }
}
.event-todo {
  background-color: $color-primary-light;
  .rbc-event,
  .rbc-day-slot .rbc-background-event {
    background-color: $color-primary-light;
  }
}
.event-scheduleitem {
  background-color: $color-success;
  .rbc-event,
  .rbc-day-slot .rbc-background-event {
    background-color: $color-success;
  }
}

.global-schedule {
  .row-category {
    .gstc__list-column-row-expander {
      margin-left: 10px;
    }
  }
  .row-child {
    .gstc__list-column-row-expander {
      width: 50px;
      display: block;
    }
    .gstc__list-column-row-expander-toggle {
      padding-left: 10px;
    }
  }
  .gstc__list-column-rows-offset--avatar {
    .row-child {
      .gstc__list-column-row-content {
        padding-left: 0px;
      }
    }
  }
}
