@import "../../../../styles/variables.scss";
.estimation-status-container {
  font-size: 16px;
  display: inline-block;

  .inner-container {
    height: auto;
    margin-top: 10px;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    font-weight: bolder;
    & i {
      margin-right: 5px;
      margin-top: 2px;
    }

    .approved-color {
      color: #fff;
      background-color: $color-success;
    }
    .draft-color {
      color: #fff;
      background-color: #6c757d;
    }
    .sent-color {
      color: white;
      background-color: $color-warning;
    }
  }

  .approved-color {
    color: #fff;
    background-color: $color-success;
  }
  .draft-color {
    color: #fff;
    background-color: #6c757d;
  }
  .sent-color {
    color: white;
    background-color: $color-warning;
  }
}
