.contacts-client {
  padding: 0;
  margin: 0;

  .reminders,
  .recent-messages {
    max-height: 695px;
  }
  .reminder-list {
    height: auto;
  }

  .tabs-table-card {
    margin: 0;
  }
}
