.estimation-navigation-toggle {
  color: gray;
  background-color: #213242;
}

.estimation-navigation-toggle ~ .dashboard-dropdown-menu {
  button {
    width: 100% !important;
  }
}
