.check-list {
  .check-list-item {
    background-color: #f8f8fc;
    min-height: 30px;
    display: flex;
    align-items: center;
    padding: 5px;
  }

  .check-list-icon {
    font-size: 12px;
    color: #7d829e;
    align-self: flex-start;
    margin-right: 15px;
    margin-top: 6px;
  }
}
