@import "../../../styles/rich-editor";

.compose-wrapper {
  display: flex;
  flex-flow: column-reverse;
  border-top: 1px solid #f0f0f6;
  @extend %rich-editor-modals;

  .public-DraftEditorPlaceholder-root {
    font-size: 20px;
    color: #d1d2de;
    letter-spacing: -0.48px;
  }

  .toolbar-wrapper {
    border-top: 1px solid #f0f0f6;
    @extend %rich-editor-toolbar;
  }

  .rdw-editor-main {
    padding: 0 20px;
    font-size: 20px;
    max-height: 300px;
    color: #213242;
  }

  .rdw-link-modal {
    bottom: 0;
    top: unset;
    left: unset;
  }

  .rdw-emoji-modal {
    bottom: 0;
    top: unset;
    left: unset;
  }

  .rdw-image-modal {
    bottom: 0;
    top: unset;
    left: unset;
  }

  .compose-tools {
    flex: 1 1 auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .send-btn-icon {
    width: 60px;
    height: 60px;

    .material-icons {
      margin: 0;
    }
  }
}
