.import-mapper {
  max-height: 100%;

  &-footer {
    display: flex;
    justify-content: flex-end;
  }

  .content-col {
    width: 35%;
  }
}
