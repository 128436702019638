@import "../../../../styles/variables.scss";
.autocomplete {
  width: 100%;

  &.is-invalid {
    .control {
      border-color: #dc3545;

      &.focused {
        border-color: #dc3545;
        box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25) !important;
      }
    }
  }

  .control {
    display: flex;
    width: 100%;
    height: 60px;
    border-radius: 0;
    background-color: #f8f8fc;
    border: 1px solid #d1d2de;

    &.focused {
      color: #495057;
      background-color: #fff;
      border-color: #80bdff;
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    }

    .value-container {
      height: 100%;
      padding: 0 20px;

      .input {
        height: 100%;
        width: 100%;
        background-color: transparent;
        border: none;
        outline: none;
        box-shadow: none;
        padding: 0;
        opacity: 1 !important;
        z-index: 1;
      }

      .multi-value-container ~ input {
        width: auto;
        margin-left: 15px;
        height: 30px;
      }

      .multi-value-container {
        height: 30px;
        border-radius: 0;

        .multi-value-label {
          background-color: #f8f8fc;
          height: 100%;
          display: flex;
          align-items: center;
          font-size: 14px;
          letter-spacing: -0.39px;
          padding: 0 10px;
          border: 1px solid #7d829e;
          border-radius: 0;
        }

        .multi-value-remove {
          border-radius: 0;
          width: 30px;
          height: 30px;
          background-color: #7d829e;
          justify-content: center;
          display: flex;
          align-items: center;

          i {
            color: white;
          }
        }
      }
    }
  }

  .dropdown-indicator {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px; //60px;
    height: 60px;

    i {
      font-size: 30px;
      color: #7d829e;
    }
  }

  .placeholder {
    font-size: 20px;
    color: #d1d2de;
    letter-spacing: -0.48px;
  }

  &.form-input-compact {
    height: $compact-input-height;
    font-size: $compact-input-font-size;
    .control {
      height: $compact-input-height;
      .value-container {
        padding: 0 10px;
        .input {
          font-size: $compact-input-font-size;
        }
      }
    }
    .dropdown-indicator {
      height: $compact-input-height;
    }
    .autocomplete-menu .menu-list .option {
      // height: $compact-input-height;
      min-height: $compact-input-height;
      font-size: $compact-input-font-size;
    }
  }
}

.menu-container {
  top: 100%;
  background-color: hsl(0, 0%, 100%);
  border-radius: 4px;
  box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1);
  margin: 8px 0;
  position: absolute;
  width: 100%;
  z-index: 1;
}

.autocomplete-menu {
  background-color: #f8f8fc !important;
  border: 1px solid #d1d2de !important;
  border-radius: 0 !important;
  z-index: 4 !important;

  .menu-list {
    padding: 0;

    .option {
      padding: 8px 12px;
      display: flex;
      align-items: center;
      min-height: 60px;
      border-top: 1px solid #d1d2de;
      background-color: #f8f8fc;
      color: #7d829e;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: -0.48px;
      font-weight: 500;

      overflow: hidden;
      text-overflow: ellipsis;
      &.option-selected {
        background-color: #eee;
      }
      &.option-badged {
        height: auto;

        .option-badged-group {
          display: flex;
          flex-direction: column;
        }
      }

      .option-badged-inline {
        display: flex;
        flex-direction: row;

        .badge {
          margin-left: 10px;
        }
      }

      &:active {
        background-color: #f0f0f6;
      }

      &:hover {
        background-color: #f0f0f6;
      }

      &:first-child {
        border-top: none;
      }
    }

    .option-content-height {
      min-height: 60px;
      height: max-content;
    }
  }
}

.autocomplete-multi {
  .control {
    min-height: 60px;
    height: auto;

    .value-container {
      padding: 5px 20px;
    }
  }
}
