.button {
  border: 0;
  border-radius: 0;
  box-shadow: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  min-width: 120px;
  height: 60px;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.48px;

  .disabled {
    opacity: 0.5;
  }

  i {
    font-size: 30px;
    margin-right: 8px;
  }
  .png-icon {
    margin-right: 8px;
  }
  &.small {
    font-size: 16px;
    line-height: 16px;
    letter-spacing: -0.48px;
    height: 40px;
    .material-icons {
      font-size: 24px;
    }
  }
  &.large {
    width: 190px;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.48px;
  }

  &.large-wide {
    width: 220px !important;
  }
  &.full-width {
    width: 100% !important;
  }

  &.max-super-large {
    max-width: 300px !important;
  }

  &.primary {
    background-color: $color-primary-light;
    color: #ffffff;
  }
  &.success {
    background-color: #3fbb64;
    color: #ffffff;
  }

  &.outline-success {
    color: #3fbb64;

    &:hover {
      color: #ffffff;
      background-color: #3fbb64;
    }
  }

  &.info {
    background-color: #f8f8fc;
    color: #7d829e;
  }
  &.info-reverse {
    background-color: #7d829e;
    color: #f8f8fc;
    &:hover,
    &:focus,
    &:active {
      background-color: darken(#7d829e, 10%) !important;
      color: #f8f8fc !important;
    }
  }

  &.fit {
    width: unset;
  }
}
