.quote-request-details-card {
  .form-input-label {
    display: none;
  }

  .form-column {
    padding: 0;
  }

  .form-input-group {
    max-width: 200px;
  }

  .footer {
    border-top: 1px solid #d1d2de;
    padding-top: 40px;
    align-items: flex-end;
    justify-content: flex-end;
    display: flex;
  }

  &.dashboard-card .body {
    overflow: visible;
  }
}
