.empty-placeholder {
  align-items: center;
  box-shadow: none;
  .empty-message {
    margin-bottom: 30px;
    font-size: 30px;
    line-height: 1.15;
    text-align: center;
  }
  .button-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 30px;
    .btn {
      display: inline-flex;
      margin-bottom: 20px;
    }
    .btn:not(:last-child) {
      margin-right: 20px;
    }
  }
}
