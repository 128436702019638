@import "../../../styles/common.scss";
@import "../../../styles/variables.scss";

.global-search-container {
  .category-select-card {
    max-height: calc(100vh - 190px);
  }
  .select-category-header {
    padding-left: 40px !important;
    justify-content: flex-start !important;
  }
  .search-input {
    padding: 0px 10px;
    width: 300px;
    color: #898da9;
    font-size: 20px;
    background-color: $text-color-light;
    border: 2px solid $border-color-primary;
    &::placeholder {
      color: #898da9;
    }
  }
}
