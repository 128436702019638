.process-timesheet-modal {
  max-width: 100%;
  width: 100%;

  .delivery-title {
    height: 60px;
    font-weight: bold;
    line-height: 60px;
  }

  .overflow-auto {
    overflow: visible !important;
  }
}
