@import "../../../../styles/rich-editor";

.rich-editor-wrapper {
  display: flex;
  // flex-flow: column-reverse;
  flex-flow: column;
  @extend %rich-editor-modals;

  .toolbar-wrapper {
    background-color: #f8f8fc;
    border: 1px solid #d1d2de;
    // border-top: none;
    border-bottom: none;
    @extend %rich-editor-toolbar;

    .rdw-option-wrapper {
      background-color: #f8f8fc;
    }
  }

  .rdw-editor-main {
    background-color: #f8f8fc;
    padding: 0 20px 20px 20px;
    height: 100%;
  }
  .rdw-editor-resize {
    resize: vertical;
  }
  .rdw-editor-m150 {
    min-height: 150px;
  }

  // .DraftEditor-editorContainer {
  //   height: 280px;
  // }

  .rdw-link-modal {
    top: 0;
    left: 0;
  }

  .rdw-emoji-modal {
    top: 60px;
  }

  .rdw-image-modal {
    top: 0;
    left: 0;
  }
  .rich-editor-input {
    padding: 5px;
    max-height: 300px;
  }
  .rdw-fullscreen-wrapper {
    flex: 1;
    justify-content: flex-end;
  }
}

.rich-editor-fullscreen {
  background-color: red;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10;
  .rich-editor-wrapper {
    height: 100%;
  }
  .rich-editor-input {
    resize: none;
    padding: 5px;
    height: calc(100vh - 30px);
    max-height: 100%;
  }
}
.public-DraftStyleDefault-block {
  margin: 10px 0px !important;
}
