.report-card {
  .row {
    margin: 0 -10px;
  }

  .report-card-body {
    overflow: unset;
  }

  .report-col {
    padding: 0 10px;
  }

  .row--big {
    margin: 0 -20px;
  }

  .report-col--big {
    padding: 0 20px;
  }

  .table-row {
    padding: 0 10px;
  }

  .main-row {
    padding-bottom: 40px;
  }

  .options-btn {
    width: 140px;
  }
  .table-title {
    height: 60px;
    font-weight: bold;
    line-height: 60px;
  }
}
