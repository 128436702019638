.lead {
  padding: 0;
  margin: 0;

  .reminders,
  .recent-messages {
    max-height: 695px;
  }
  .reminder-list {
    height: auto;
  }

  .tabs-table-card {
    margin: 0;
  }

  .header-text {
    color: #7d829e;
    font-family: Helvetica, sans-serif;
    letter-spacing: -0.48px;
  }
  .name {
    font-size: 30px;
    line-height: 1.15;
  }
  .description {
    font-size: 20px;
    line-height: 24px;
    white-space: pre-line;
  }
  .row {
    margin-left: 0;
  }
  .fontBolder {
    font-weight: bolder;
  }
  &.loader-center {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .badge-style {
    background-color: #3fbb64;
  }

  .dashboard-card .body {
    overflow: unset;
  }

  & .lead-notes .dashboard-card .body {
    overflow: auto;
  }
  .tasks-list {
    height: auto;
    .table-responsive {
      padding: 20px;
      overflow-x: initial;
    }
  }
  .contact-name-item {
    transition: transform 0.1s linear;
    cursor: pointer;
    &:hover {
      transform: scale(1.04);
    }
    &::after {
      content: ",";
      display: inline-block;
      margin-right: 5px;
    }
    &:last-child:after {
      content: "";
      display: inline-block;
    }
  }
}
