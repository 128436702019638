.add-team-member-modal {
  max-width: 860px;
  width: 100%;
}

.item-description-wrapper {
  width: 100%;
  margin-top: 20px;

  .item-description {
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.48px;
    color: #7d829e;
    width: 100%;
    max-width: 700px;
    margin: 15px 10px;
  }

  .item-description-head {
    font-weight: bold;
    width: 100%;
    max-width: 700px;
    margin: 15px 10px;
  }
  .item-role {
    font-weight: 500;
  }
}
