@import "../../../styles/variables";
.authentication-card-container {
  &.sign-up-activate-card {
    width: 820px;
    max-width: 100%;
    .field-text {
      white-space: normal;
    }
    a {
      color: $color-primary;
      display: inline-block;
      margin-left: 15px;
    }
  }
}
