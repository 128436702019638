@import "../../styles/common.scss";
@import "../../styles/variables";

.datePickerContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  background-color: $text-color-primary-dark;
  color: $text-color-light;
  .datePickerLabel {
    padding: 0.5em;
    font-size: 20px;
    font-weight: bold;
  }

  .datePickerRange {
    width: 90px;
    height: 60px;
    background-color: $text-color-primary-dark;
    font-weight: bold;
    color: $text-color-light;
    cursor: pointer;
    border: none;
    outline: none;
    text-align: center;
    &:hover {
      background-color: #426383;
      border-color: #426383;
    }
    &:focus {
      background-color: #426383;
    }

    &::placeholder {
      color: $text-color-light;
    }
  }
  .reset-icon-container {
    width: 30px;
    button {
      padding: 0 10px 0 0;
      display: flex;
      outline: none;
      border: none;
      background: none;
      color: $text-color-light;
      opacity: 0.7;
      transition: opacity 0.2s linear;
      &:hover {
        opacity: 1;
      }
    }
  }
}
.widthWithIcon {
  width: 350px;
}
