@import "../../../../../styles/variables.scss";

.estimation-item {
  border-bottom: 1px solid $border-color-light;
  &:last-child {
    border-bottom: 0;
  }
  &--description {
    white-space: pre-wrap;
  }
  &--creation {
    flex-direction: row;
    display: flex;
    .field-text {
      font-size: 16px;
    }
  }
  .material-icons {
    font-size: 20px;
    color: $color-primary;
  }
  .unlink {
    font-size: 16px;
  }
  &-content {
    a {
      text-decoration: none;
      &:hover {
        color: $text-color-primary-dark;
      }
    }
  }
}
