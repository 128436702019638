.material-icons-outlined,
.material-icons.material-icons--outlined
  //.material-icons-two-tone,
  //.material-icons.material-icons--two-tone,
  //.material-icons-round,
  //.material-icons.material-icons--round,
  //.material-icons-sharp,
  //.material-icons.material-icons--sharp {
{
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

.material-icons-outlined,
.material-icons.material-icons--outlined {
  font-family: "Material Icons Outlined";
}

//.material-icons-two-tone,
//.material-icons.material-icons--two-tone {
//  font-family: 'Material Icons Two Tone';
//}
//
//.material-icons-round,
//.material-icons.material-icons--round {
//  font-family: 'Material Icons Round';
//}
//
//.material-icons-sharp,
//.material-icons.material-icons--sharp {
//  font-family: 'Material Icons Sharp';
//}
