@import "../../../styles/variables.scss";

.send-email-modal {
  width: 100%;
  max-width: 680px;
  .rich-editor-wrapper {
    .rdw-editor-main {
      min-height: 250px;
    }
  }
  .table-card {
    margin-bottom: 10px;
  }
  .toggles-container {
    display: flex;
    flex-direction: row;
    .form-input-toggle {
      margin-right: 20px;
    }
  }
  .table-form-controls {
    padding: 0px 5px;
  }

  .select-documents-button {
    height: 50px;
    background-color: $color-primary-light;
    color: $text-color-light;
  }
  .ra {
    background-color: $color-light;
    padding: 5px 10px;
    border: 1px solid $border-color-primary;
    border-bottom: 0;
    &:last-child {
      border-bottom: 1px solid $border-color-primary;
    }
    .ra-name {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .ra-remove {
      cursor: pointer;
      i {
        font-size: 20px;
      }
    }
  }
}
