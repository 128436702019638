@import "../../../styles/variables.scss";

.conversation {
  height: calc(100vh - #{$header-height} - 80px);

  .conversation-name {
    padding: 0;
    justify-content: center;
    text-transform: capitalize;
  }

  .conversation-messages {
    margin: 0;
    display: flex;
    flex-flow: column-reverse;

    .empty-placeholder {
      height: 100%;
      justify-content: center;

      .body {
        height: auto;
      }
    }
  }

  .chat-title {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 60px;
  }

  .sub-header-height {
    height: 120px;
    flex-direction: column;
  }

  .sub-header-container {
    display: flex;
    align-items: center;
    width: 100%;
    height: 60px;
    background-color: $color-light;
    color: $color-primary-light;
    border-bottom: 1px solid $border-color-light;
  }

  .sub-header-text {
    text-transform: none;
  }

  .conversation-footer {
    padding: 0;
  }
}
