.job-overview {
  padding: 0;
  margin: 0;

  .tabs-table-card {
    margin: 0;
  }

  .header-text {
    color: #7d829e;
    font-family: Helvetica, sans-serif;
    letter-spacing: -0.48px;
  }

  .name {
    font-size: 30px;
    line-height: 1.15;
  }

  .row {
    margin-left: 0;
  }

  .fontBolder {
    font-weight: bolder;
  }

  .checklists {
    .communication-body {
      .success {
        width: 205px;
      }
    }

    .dashboard-card .body {
      overflow: unset;
    }

    .wrapper {
      border-bottom: 3px solid #f0f0f6;
    }

    height: auto;

    .table-responsive {
      overflow-x: initial;
    }

    .communication-footer {
      justify-content: center;

      button {
        margin-left: 0px;
        width: 205px;
      }
    }
  }
}
