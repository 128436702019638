.client-header-container__open {
  width: 420px;
}

.client-header-container {
  padding: 0 10px;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: flex-end;
  min-width: 140px;

  .add-btn {
    height: 35px;
    font-weight: normal;
    font-size: 16px;
  }

  .client-header-dropdown {
    border-radius: 3px;
    border: 1px solid #d1d2de;
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 10;
    max-height: 400px;
    width: 400px;
    display: flex;
    flex-direction: column;
    background-color: #fefefe;
  }

  .client-selector {
    border-bottom: 1px solid #d1d2de;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 15px;
  }

  .client-list {
    overflow: auto;
    height: 300px;
  }

  .client-empty-message {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .client-loader {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 4;
    background-color: #f8f8fc;
    opacity: 0.75;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
