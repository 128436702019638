.authentication-card-container {
  &.sign-up-card {
    .sign-in-button {
      margin-left: 20px;
      text-decoration: none;
      .button {
        width: auto;
        white-space: nowrap;
      }
    }
    .sign-up-submit {
      flex-direction: row-reverse;
    }
    @media (max-width: 920px) {
      .sign-up-other {
        margin-top: 40px;
        flex-direction: column;
      }
      .button {
        width: 100%;
      }
    }
  }
  label a {
    font-size: inherit;
  }
}
