@import "../../styles/common";
@import "../../styles/variables.scss";

.accept-signature-dialog {
  .signature-container {
    padding: 0 25px;

    .signature-title {
      margin-bottom: 15px;
    }

    .canvas-container {
      .sigCanvas {
        border: 1px solid $border-color-primary;
        background-color: $color-light;
      }

      .sigCanvas-error {
        border: 1px solid $color-error;
      }
    }
  }

  .modal-dialog {
    max-width: 750px;
  }

  .signature-title {
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.48px;
    color: #7d829e;
  }

  .header {
    @extend %modal-header;

    .title {
      @extend %modal-header-title;
    }
  }

  .body {
    display: flex;
    padding: 20px;
  }

  .footer {
    justify-content: space-between;
    padding: 10px 25px;
    border: none;

    .success {
      margin-left: 10px;
    }
  }
}
