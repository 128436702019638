.payment-modal {
  max-width: 680px;
  width: 100%;

  .form-input {
    padding: 20px;
  }
  .button[type="submit"] {
    width: 250px;
  }
}
