@import "../../../styles/variables.scss";

.variation-card {
  .signature-container {
    height: 100px;
    background: $color-light;
  }

  .signature {
    height: 100px;
    width: 100%;
    object-fit: contain;
  }
}
