.upload-spinner {
  z-index: 1061;
  &--file {
    max-width: 320px;
    overflow: hidden;
    font-size: 14px;
  }
  &--filename {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
.upload-spinner-backdrop {
  z-index: 1060;
}
