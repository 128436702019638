.selection-options {
  max-width: 800px;
  width: 100%;

  .modal-body {
    padding: 20px;
    padding-left: 10px;
    .quote-selection-container {
      width: 100%;
    }
  }
  .item-costing-path {
    max-width: 200px;
  }

  .badge {
    margin-left: 10px;
    padding: 5px 15px;
    display: inline-block;
    border-radius: 0px;
    font-size: 16px;
    font-weight: 400;
  }
}
