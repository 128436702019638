@import "../../../styles/common";
@import "../../../styles/variables";

.site-diary-list {
  max-height: 100%;
  height: 100%;

  .site-diary-header {
    padding: 0;
    justify-content: center;
    text-transform: capitalize;
  }

  .site-diary-body {
    flex: 1 1 auto;
    margin: 0;
  }

  .site-diary-footer {
    border-top: 1px solid #f0f0f6;
    justify-content: flex-end;
    padding: 40px;
    display: flex;
  }

  .site-diary-empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 30px 20px;

    .empty-message {
      margin-bottom: 20px;
      text-align: center;
      word-break: break-word;
    }
  }
}

.site-diary-list-item {
  @extend %conversation-container;

  &--selected {
    background-color: #f8f8fc;
  }

  .site-diary-content {
    display: flex;
  }

  .site-diary-info {
    flex: 1 1 auto;
    min-width: 0;
    padding: 10px;
  }

  .site-diary-title {
    @extend %message-header;
    color: #314961;
  }

  .site-diary-date {
    color: $text-color-secondary;
  }

  .site-diary-name {
    @extend %message-title;
  }

  .site-diary-message {
    @extend %message-content;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    max-height: 48px;
  }
  .site-diary-shared {
    margin-top: 10px;
  }
}
.entry-group-list {
  &--open {
    .quote-request-quotes-list {
      max-height: 100vh !important;
    }
  }
  .entry-group-item {
    // font-weight: bold;
    padding: 1rem 0;
    border-bottom: 1px solid #dee2e6;
    align-items: center;
    display: flex;
    line-height: 40px;
    cursor: pointer;
    &--title {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
  .material-icons {
    font-size: 32px;
    line-height: 0;
  }

  span {
    margin-left: 0.5rem;
    font-size: 0.8em;
    white-space: nowrap;
  }
}
