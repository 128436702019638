@import "../../../styles/variables.scss";

.scale-section {
  .plan-viewer-toolbar-left {
    .polyline {
      .png-icon {
        transform: rotate(45deg);
      }
    }

    .vertical-polyline {
      .png-icon {
        transform: rotate(-45deg);
      }
    }

    .vertical-polyline.enabled {
      background-color: $color-light;

      svg {
        fill: $color-primary-light;
      }
    }
  }
}

.scale-section-active-vertical-polyline {
  .plan-viewer-toolbar-left {
    .polyline {
      background-color: $color-light;

      .png-icon {
        svg {
          fill: $color-primary-light;
        }
      }
    }

    .vertical-polyline.enabled {
      background-color: $color-primary-light;

      .png-icon {
        svg {
          fill: $color-light;
        }
      }
    }
  }
}
