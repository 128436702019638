@import "../../../../styles/variables";
.subscription-card {
  .footer {
    border-top: 1px solid #efefef;
    padding-top: 40px;
    .button {
      margin-left: 0;
      margin-right: 15px;
    }
  }
}
