@import "../../../styles/variables.scss";
.site-diary-modal {
  width: 100%;
  max-width: 1380px;
  .modal-footer {
    border-top: 1px solid #d1d2de;
    .form-group {
      margin-bottom: 0;
      max-width: 300px;
    }
  }
  .sd-row {
    display: flex;
    width: 100%;
    border-top: 1px solid $border-color-primary;
    margin-top: 1rem;
    align-items: flex-start;
    .sd-row-count {
      width: 48px;
      margin-top: 1rem;
    }
    .sd-row-field {
      flex: 1;
      .form-label {
        max-width: 100%;
        height: auto;
        min-height: 60px;
      }
    }
    .sd-row-upload {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 60px;
      margin-left: 10px;
      cursor: pointer;
      &.sd-row-upload-table {
        margin-top: 1rem;
      }
    }
    .sd-row-upload-icon {
      width: 60px;
      height: 60px;
      background-color: $text-color-primary;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      i {
        font-size: 30px;
        line-height: 1;
      }
    }
    .table-form-controls {
      padding: 1rem 0;
    }
    .form-input-label,
    .table-form-title {
      font-weight: 500;
    }
    .table-hover tbody tr:hover + tr.sd-table-row-attachments {
      background-color: rgba(0, 0, 0, 0.075);
    }
    .sd-table-row-attachments {
      &:hover {
        background-color: transparent;
      }
      td {
        padding: 0;
        border-top: 0;
      }
    }
  }
  .sd-upload-row {
    &.sd-upload-row-indent {
      margin-left: 48px;
    }
    .attachments-list {
      border-top: 0;
    }
  }
  .sd-sub-row {
    width: 100%;
    padding: 0 48px;
  }
}
