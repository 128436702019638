@import "../../../styles/common.scss";
@import "../../../styles/variables.scss";

.pdf-viewer-modal {
  width: 100%;
  max-width: 100%;
  transform: translateX(0px) !important;

  .pdf-row {
    display: flex;
    flex-direction: row;
  }

  .pdf-sidebar {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 200px;
    max-height: calc(100vh - 60px);
    overflow-y: auto;
  }

  .modal-body {
    padding: 0;
    height: calc(100vh - 60px);
  }

  .pdf-col {
    background-color: #d8d8d8;
    height: 100%;
    width: 100%;
  }
  .pdf-row {
    height: calc(100vh - 60px);
  }

  .pdf-preview {
    background-color: #d8d8d8;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    max-height: calc(100vh - 60px);
    overflow-y: auto;

    .react-pdf__Page {
      margin-bottom: 20px;
    }
  }

  .rdw-editor-main {
    min-height: 150px;
  }

  .spinner {
    width: 50px;
    height: 50px;
  }

  .spinner-container {
    width: 100vw;
    height: calc(100vh - 60px);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .page-preview {
    border: 4px solid $border-color-primary;
    cursor: pointer;
    margin-bottom: 5px;
    &.active {
      border: 4px solid $color-success;
    }
  }

  .preview-icon {
    font-size: 29px;
  }
}
