.take-off-form-modal {
  // min-width: 840px;
  max-width: 100%;

  .take-off-colour-picker {
    // width: 385px;
  }

  .button-select-input {
    .button {
      min-width: 100px;
    }
  }
}
