@import "../../../styles/common";

.conversation-item {
  @extend %conversation-container;

  &--selected {
    background-color: #f8f8fc;
  }

  .conversation-avatar {
    flex: 0 0 60px;
  }

  .conversation-content {
    display: flex;
  }

  .conversation-info {
    flex: 1 1 auto;
    padding-left: 20px;
    min-width: 0;
    .text-truncate {
      max-width: 250px;
    }
  }

  .conversation-title {
    @extend %message-header;
  }

  .conversation-name {
    @extend %message-title;
  }

  .conversation-message {
    @extend %message-content;
  }
}
