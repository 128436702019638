@import "../../../styles/variables.scss";

.dashboard {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  position: relative;
  .dashboard-main {
    height: 100%;
    max-width: calc(100% - 60px);
    flex: 1;
    // margin-left: 60px;
    transition: margin-left 0.2s;

    &.side-menu-expanded {
      // margin-left: 250px;
      transition: max-width 0.2s;
      max-width: calc(100% - 250px);
      .lead {
        .filters-container {
          width: calc(100% - 290px);
        }
      }
    }
  }

  .dashboard-content {
    height: calc(100% - 60px);
    overflow: auto;
    padding: 20px;
  }

  @media (max-width: 920px) {
    .dashboard-content {
      height: auto;
      overflow: auto;
      padding: 20px;
    }
  }

  .expand-button-container {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    border: none;
    color: $color-primary;
    background-color: transparent;
    position: absolute;
    top: 70px;
    left: 35px;
    z-index: 1;
    transition: all 0.2s;
    &:hover {
      i {
        background-color: #3fbb64;
        color: $text-color-light;
      }
    }
    i {
      display: inline-block;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background-color: $text-color-light;
      box-shadow: 0px 0px 6px 0px $color-primary-dark;
      transition: all 0.4s;
    }
  }
  .active {
    left: 225px;
  }
  .active-transparent {
    opacity: 0;
    &:hover {
      opacity: 1;
    }
  }
  .active-visible {
    opacity: 1;
  }
}
