.page-action {
  display: flex;
  height: 60px;
}

.header-action-button {
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  height: 100%;
  font-size: 18px;
  color: #feffff;
  letter-spacing: -0.43px;
  font-weight: 600;
  cursor: pointer;

  &:hover {
    background-color: #213242;
  }

  .icon-button {
    margin-right: 0;
  }

  i {
    font-size: 30px;
    margin-right: 5px;
  }
}
