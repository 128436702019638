.emoji {
  display: inline-flex;
  vertical-align: middle;
  position: relative;

  .emoji-text {
    z-index: -1;
    top: 0;
    left: 0;
    position: absolute;
    color: transparent;
  }
}
