@import "../../../styles/common";

.reminder-item {
  cursor: initial;
  @extend %conversation-container;

  &-header {
    @extend %message-header;
  }

  &-name {
    @extend %message-title;
  }

  &-description {
    white-space: pre-wrap;
    @extend %message-content;
  }

  &-statuses {
    flex-wrap: wrap;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    .right-actions {
      align-items: center;
      display: flex;
    }

    .remove-action {
      color: #7d829e;
      margin-left: 20px;
    }
  }

  &-status {
    user-select: none;
    cursor: pointer;
    min-height: 40px;
    background-color: #f8f8fc;
    padding: 0 13px;
    display: flex;
    align-items: center;
    font-weight: 500;

    .status-icon {
      color: #7d829e;
      margin-right: 7px;
      font-size: 20px;
    }

    &--disabled {
      background-color: rgba(#d1d2de, 0.1);

      .field-text,
      .status-icon {
        color: #d1d2de;
      }
    }

    &--completed {
      .field-text,
      .status-icon {
        color: #3fbb64;
      }

      background-color: rgba(#3fbb64, 0.1);
    }
  }
}
