@import "../../../styles/variables";
.dashboard-jobs {
  margin-bottom: 30px;
}
.dashboard-job {
  display: flex;
  color: $text-color-primary;
  align-items: center;
  padding: 20px;
  cursor: pointer;
  border-bottom: 1px solid #eee;
  .job-icon {
    min-width: 48px;
    width: 48px;
    height: 48px;
    background-color: $color-primary;
    color: #fff;
    margin-right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    .material-icons {
      font-size: 24px;
    }
  }
  .job-date {
    margin-left: 1em;
    color: #aaa;
    white-space: nowrap;
    font-size: 14px;
  }
}
