%rich-editor-toolbar {
  border: none;
  border-radius: 0;
  padding: 0;
  margin: 0;

  .rdw-block-wrapper {
    width: 140px;
    .rdw-block-dropdown {
      width: 140px;
    }
  }

  .rdw-link-wrapper {
    margin: 0;
  }

  .rdw-fontsize-wrapper {
    width: 60px;
  }

  .rdw-dropdown-wrapper {
    border: none;
    border-radius: 0;
    box-shadow: none;
    background-color: #f8f8fc;
    a {
      font-size: 16px;
    }
  }

  .rdw-image-wrapper,
  .rdw-emoji-wrapper,
  .rdw-fontsize-wrapper,
  .rdw-block-wrapper,
  .rdw-history-wrapper,
  .rdw-inline-wrapper,
  .rdw-text-align-wrapper,
  .rdw-list-wrapper {
    margin: 0;
  }

  .rdw-option-wrapper {
    height: 30px;
    margin: 0;
    width: 30px;
    border-radius: 0;
    border: none;

    .material-icons {
      font-size: 20px;
      color: #7d829e;
    }

    &.rdw-option-active {
      background-color: #7d829e;

      .material-icons {
        color: white;
      }

      img {
        filter: brightness(0) invert(1);
      }
    }

    &:hover {
      .material-icons {
        color: white;
      }

      box-shadow: none;
      background-color: darken(#7d829e, 20%);

      img {
        filter: brightness(0) invert(1);
      }
    }
  }
}

%rich-modal-btn {
  background-color: #3fbb64;
  color: #ffffff;
  box-shadow: none;

  &:disabled {
    background-color: #f8f8fc;
    color: #7d829e;
  }
}

%rich-editor-modals {
  .rdw-link-modal {
    height: 250px;

    .rdw-link-modal-label {
      color: #7d829e;
    }

    .rdw-link-modal-btn {
      @extend %rich-modal-btn;
    }
  }

  .rdw-image-modal {
    .rdw-image-modal-btn {
      @extend %rich-modal-btn;
    }
  }
}
