.comment-form {
  .name-input {
    background: #f8f8fc;
    border: 1px solid #d1d2de;
    width: 100%;
    min-height: 120px;
    padding: 10px;
  }

  .popup-actions {
    margin-top: 10px;
  }

  .button {
    border: none;
    box-shadow: none;
    outline: none;
    width: 30px;
    height: 30px;
    background-color: #f8f8fc;

    i {
      font-size: 18px;
      margin: auto;
      color: #7d829e;
    }

    &.close-button {
      margin-right: 10px;
    }

    &.submit-button {
      background-color: #3fbb64;

      i {
        color: #ffffff;
      }
    }
  }
}

.leaflet-popup {
  .leaflet-popup-content-wrapper {
    background: #ffffff;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
    border-radius: 0;
  }

  .leaflet-popup-content {
    margin: 10px;
  }

  .leaflet-popup-tip-container {
  }

  .leaflet-popup-close-button {
    display: none;
  }
}
.leaflet-label {
  width: 120px !important;
  font-size: 20px;
  font-weight: bold;
  z-index: 1000;
  background-color: rgba(255, 255, 255, 0.5);
  height: 30px !important;
  border-radius: 5px;
  &-deduction,
  &-polygon {
    text-align: center;
    left: -60px;
    top: -10px;
  }
  &-hidden {
    width: 0 !important;
    height: 0 !important;
    opacity: 0;
  }
}
