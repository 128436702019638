.teammate-header-container__open {
  width: 420px !important;
}

.teammate-header-container {
  padding: 0 10px;
  display: flex;
  align-items: center;
  position: relative;
  width: 250px;
  justify-content: flex-end;

  .add-btn {
    height: 35px;
    font-weight: normal;
    font-size: 16px;
    min-width: 130px;
  }

  .teammate-header-dropdown {
    border-radius: 3px;
    border: 1px solid #d1d2de;
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 10;
    max-height: 400px;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #fefefe;
  }

  .teammate-selector {
    border-bottom: 1px solid #d1d2de;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 15px;
  }

  .teammate-list {
    overflow: auto;
    height: 300px;
  }

  .teammate-empty-message {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .teammate-loader {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 4;
    background-color: #f8f8fc;
    opacity: 0.75;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
