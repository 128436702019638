@import "../../../styles/tabs";

.tabs-table-card {
  margin-right: 20px;
  margin-bottom: 20px;

  .header {
    padding: 0;
    @extend %dashboard-card-tabs;
  }

  .table {
    font-size: 20px;
    letter-spacing: -0.48px;
    line-height: 24px;

    .table-header {
      height: 60px;

      tr {
        th {
          border-top: 0;
        }
      }
    }

    .table-body {
      tr {
        &.clickable {
          cursor: pointer;
        }

        .row-actions {
          color: #7d829e;

          .action {
            margin-right: 20px;
            cursor: pointer;
          }
        }
      }
    }

    tr {
      height: 60px;

      th,
      td {
        vertical-align: middle;
      }
    }
  }

  .footer {
    display: flex;
    justify-content: flex-end;
  }
}
