.form-input {
  min-width: 400px;
  height: 60px;
  border-radius: 0;
  border-color: #d1d2de;
  background-color: #f8f8fc;
  &::placeholder {
    color: #d9d9e4;
  }
}

.form-input-label {
  display: flex;
  align-items: center;

  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.48px;
  color: #7d829e;
  height: 60px;
  width: 100%;
  // max-width: 400px;
  margin: 0;
}

.form-input-group {
  flex-wrap: nowrap;
  &.form-input-group-multi {
    .form-input {
      height: auto;
      min-height: 60px;
    }
  }
  &.form-input-group-disabled {
    .autocomplete .control {
      background: #e9ecef;
    }
  }
  .append {
    .text {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 30px;
      font-weight: 600;
      color: #ffffff;
      letter-spacing: -0.48px;
      text-align: center;
      border: none;
      border-radius: 0;
      min-width: 60px;
      background-color: #7d829e;
      user-select: none;

      i {
        font-size: 30px;
      }
    }
    .success {
      background-color: $color-success;
    }
    .warning {
      background-color: $color-warning;
    }
    .primary {
      background-color: $color-primary;
    }
    .error {
      background-color: $color-error-light;
    }
  }
}
.form-check-input {
  &[type="checkbox"] {
    width: 20px;
    height: 20px;
    margin-top: 0.15rem;
    margin-left: -1.5rem;
  }
}
.form-control:focus {
  z-index: inherit;
}
