.floating-takeoff-modal {
  width: 100%;
  max-width: 100%;
  .modal-content {
    height: 100%;
  }
  .modal-body {
    flex-direction: column;
    padding: 20px;
  }
  .modal-footer {
    padding: 20px 0 0 0;
    justify-content: center;
    .quantity-total {
      position: absolute;
      left: 20px;
      font-size: 30px;
      font-weight: bold;
    }
  }
  .plans-card {
    width: 100%;
    min-height: auto;
    .leaflet-draw-guide-dash {
      z-index: 1;
    }
  }
}
