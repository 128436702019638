@import "../../../styles/variables";
.take-off-card {
  .body {
    .add-item {
      width: 100%;
      margin-bottom: 40px;
    }

    .take-off-list {
      list-style: none;
      padding: 0;
      margin: 0;
      max-height: calc(100vh - 400px);
      .take-off-list-item {
        font-family: Helvetica;
        font-size: 20px;
        color: #7d829e;
        cursor: pointer;
        .item-total {
          letter-spacing: -0.48px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          min-height: 50px;
          background: #ffffff;
          box-shadow: 0 1px 0 0 #d1d2de;
          margin-bottom: 1px;
          padding: 0 15px;
        }

        &.selected,
        &.selected .item-total,
        &:hover .item-total,
        &:hover {
          background-color: #f8f8fc;
        }

        .item-info {
          display: flex;
          align-items: center;
          height: 100%;
          width: 100%;
          line-height: 1.25;
        }
        .item-color {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          border: 1px solid #7d829e;
          margin-right: 10px;
          flex-shrink: 0;
        }

        .action {
          height: 50px;
          width: 40px;
          display: flex;
          justify-content: center;
          align-items: center;

          i {
            font-size: 24px;
          }
        }
      }
      .take-off-page-list,
      .take-off-shapes-list {
        font-size: 16px;
        list-style: circle;
        margin: 0 20px;
        padding: 10px 0 0 0;
        &:last-child {
          padding-bottom: 10px;
        }
        .action {
          height: auto;
          width: auto;
          margin-left: 10px;
        }
        .take-off-shapes-list {
          padding: 10px 0 0 0;
          margin: 0;
          &-icon {
            color: $color-primary;
            width: 20px;
            height: 20px;
            margin-right: 5px;
            .material-icons {
              width: 20px;
              height: 20px;
              font-size: 20px;
            }
            .png-icon {
              svg {
                fill: $color-primary;
                width: 20px;
                height: 20px;
              }
            }
          }
          &-label {
            font-style: italic;
          }
        }
      }
      .take-off-shapes-list {
        li {
          border-bottom: 1px solid $border-color-light;
          padding: 5px 0;
          &:last-child {
            border-bottom: 0;
          }
        }
      }
    }
  }
}
