@import "../../../styles/variables";

.table {
  font-size: 20px;
  letter-spacing: -0.48px;
  line-height: 24px;

  .check-cell {
    width: 60px;

    .form-check {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0;
      padding: 0;

      input {
        width: 20px;
        height: 20px;
        margin: 0;
      }
    }
  }

  .count-cell {
    width: 48px;
    padding: 12px 0;
  }

  .table-header {
    height: 60px;

    tr {
      th {
        z-index: 2;
        position: sticky;
        background-color: white;
        top: 0;
        border-top: 0;
      }
    }
    &--sortable {
      cursor: pointer;
      div {
        display: flex;
        align-items: center;
      }
    }
  }

  .table-header-nowrap {
    height: 60px;

    tr {
      th {
        white-space: nowrap;
      }
    }
  }

  .table-header--disabled {
    tr {
      th {
        color: #bfc1cf;
        font-weight: normal;
      }
    }
  }

  .table-body {
    .table-row {
      &--clickable {
        cursor: pointer;
      }
      &--subrow {
        font-style: italic;
        height: 45px;
        td {
          padding: 0.5rem 0.75rem 0.5rem 1.25rem;
          font-size: 18px;
        }
      }
      &--total {
        font-weight: bold;
        border-top: 2px solid darken(#d1d2de, 10%);
      }
      .icon {
        width: 30px;
      }

      .row-actions {
        color: #7d829e;

        .action {
          min-width: 0;
          width: unset;
          height: unset;
          margin-right: 0px;
          background-color: transparent;
          padding: 6px;
          position: relative;

          .action-icon {
            color: #7d829e;
            &.png-icon svg {
              fill: #7d829e;
            }
          }
        }
        .badge {
          position: absolute;
          font-size: 10px;
          right: 1px;
          top: 4px;
          width: 15px;
          height: 15px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .dropdown-toggle {
          &,
          &:hover,
          &:focus,
          &:active {
            background-color: transparent !important;
            color: $color-primary;
          }
        }
      }
    }
  }

  tr {
    height: 60px;

    th,
    td {
      vertical-align: middle;
    }
  }

  .disabled-row {
    height: 81px;

    &:hover {
      background-color: initial;
    }
  }

  .disabled-column {
    padding: 21px 10px 0 10px;
    background-clip: content-box;
    background-color: #f8f8fc;
  }
}

.table--full-height {
  max-height: 100%;
  height: 100%;
}

.table--withoutBoxShadow {
  box-shadow: none;
}

.table-card {
  // margin-right: 20px;
  margin-bottom: 20px;

  .table-body--hidden {
    overflow-x: hidden !important;
  }

  .table-responsive {
    overflow-x: unset;
  }

  .table--overflow {
    overflow: inherit;
  }

  .card-header--default {
    text-transform: unset;
  }

  .card-header--disabled {
    background-color: #d6dbe0;
  }

  .footer {
    display: flex;
    justify-content: flex-end;

    .dashboard-dropdown-toggle,
    .dashboard-dropdown-item {
      background-color: #7d829e;

      &:hover {
        background-color: #636885;
      }
    }
  }

  .table-form {
    tr {
      td {
        padding: 21px 10px;
        vertical-align: middle;
      }
    }
  }
}

.table-card-responsive {
  width: 100%;
}

.table-outline {
  border: 1px solid #d1d2de;

  .table-header tr th {
    border-top: 1px solid #d1d2de;
    background-color: #f8f8fc;
  }

  .count-cell {
    padding: 12px;
  }
  .table-cell-link {
    color: $color-success;
    cursor: pointer;
  }
}

.table-attachments {
  .row-actions {
    justify-content: flex-end;
  }
}

.table-tooltip {
  background-color: $color-primary !important;
  font-size: 20px !important;
  max-width: 400px;
  &.place-left {
    &:after {
      border-left-color: $color-primary !important;
      border-left-style: solid !important;
      border-left-width: 6px !important;
    }
  }
}

.table-compact {
  .table-row {
    height: $compact-table-row-height;
    td,
    th {
      padding: 5px 10px;
    }
  }
}
