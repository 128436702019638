.tag-container {
  flex-wrap: wrap;
  outline: none;
  .tag-badge {
    padding: 10px 15px;
    margin-right: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
    background: #7d829e;
  }
  .editButton {
    outline: none;
    border: none;
    background-color: transparent;
  }
  .input-tags-container {
    position: relative;
    padding-right: 51px;
  }
  .submitButton {
    width: 61px;
    height: 50px;
    position: absolute;
    right: 0; //-51px;
    top: 0;

    border: none;
    outline: none;
    background-color: #3fbb64;

    & i {
      margin-top: 2px;
    }
  }
  .material-icons {
    margin: 0 !important;
    font-size: 24px !important;
  }
  .input-style {
    background-color: #3fbb64;
    height: 30px;
  }
  .form-group {
    margin-bottom: 0;
  }
  .form-input {
    min-width: 300px;
  }
  .control,
  .form-input-group.form-input-group-multi .form-input {
    min-height: 50px;
    height: auto;
  }
  .control,
  .autocomplete .dropdown-indicator {
    height: auto;
  }
  .tags-placeholder {
    margin-bottom: 5px;
    font-weight: 400;
    color: #7d829e;
  }
  .autocomplete-menu {
    z-index: 2000;
  }
}
