.dashboard-sidebar {
  height: 100%;
  width: 250px;
  background-color: #314961;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  // float: left;
  transition: width 0.2s;

  &.minified {
    width: 60px;

    .header {
      width: 60px;
    }

    .navigation-list {
      .navigation-item {
        &.logout {
          width: 60px;
        }
      }
    }
  }

  .header {
    width: 250px;
    height: 60px;
    transition: width 0.2s;

    .logo {
      user-select: none;
      width: 100%;
      height: 100%;
      background-color: #3fbb64;
      display: flex;
      padding-left: 20px;
      align-items: center;
    }
  }

  .navigation-list {
    list-style: none;
    padding: 0;

    &.nested {
      background-color: #213242;
      display: none;
      color: #ffffff;

      &.expanded {
        display: block;
      }

      .navigation-item {
        text-decoration: none;
        &.selected {
          color: #ffffff;
          border-left: none;
          padding-left: 15px;

          .png-icon {
            svg {
              fill: #ffffff;
            }
          }
        }
      }
    }

    .navigation-item {
      width: 100%;
      height: 34px;
      color: rgba(255, 255, 255, 0.5);
      display: flex;
      overflow: hidden;
      padding: 5px 5px 5px 15px;
      user-select: none;

      font-weight: bold;
      font-size: 16px;
      line-height: 22px;
      cursor: pointer;

      .png-icon {
        width: 24px;
        height: 24px;
        svg {
          width: 24px;
          height: 24px;
          fill: rgba(255, 255, 255, 0.5);
        }
      }

      &.logout {
        position: absolute;
        width: 250px;
        bottom: 0;
      }

      &.selected {
        color: #3fbb64;
        border-left: 5px solid #3fbb64;
        padding-left: 10px;

        .png-icon {
          svg {
            fill: #3fbb64;
          }
        }
      }

      &:hover {
        background-color: #213242;
        color: #3fbb64;
        text-decoration: none;
        .png-icon {
          svg {
            fill: #3fbb64;
          }
        }
      }

      .icon {
        width: 25px;
        margin-right: 10px;
        i {
          font-size: 24px;
        }
      }

      .title {
        display: flex;
        align-items: center;
        white-space: nowrap;
        height: 100%;
      }

      .rightAccessory {
        margin-right: 0;
        margin-left: auto;
        i {
          font-size: 24px;
        }
      }
    }
  }
}
