@import "../../../styles/common";

.jobs-timesheets-categories {
  max-height: 100%;

  .jobs-timesheets-list {
    .jobs-timesheets-list-item {
      @extend %category-item;
      box-shadow: 0 1px 0 0 #d1d2de;
      margin-bottom: 1px;

      &:hover {
        background-color: #fcf8f8;
      }

      .right-side {
        display: flex;
        align-items: center;

        .item-total {
          margin-right: 15px;
        }

        .remove-action {
          height: 60px;
          width: 24px;
          display: flex;
          justify-content: center;
          align-items: center;
          user-select: none;
          cursor: pointer;

          i {
            font-size: 24px;
          }
        }
      }
    }
  }

  .jobs-timesheets-selected {
    background-color: #fcf8f8;
  }
  .divider {
    border: 1px solid #d1d2de;
    margin: 40.5px 0;
  }
}
