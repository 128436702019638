@import "../../styles/common";

.attachments-list {
  border-top: 1px solid #f0f0f6;
  position: relative;
  width: 100%;

  .attachment-wrapper {
    padding: 10px;
  }
  .attachment-list-wrapper {
    max-height: 270px;
    overflow: auto;
    display: flex;
    flex-wrap: wrap;
  }

  .attachments-clear {
    z-index: 1;
    cursor: pointer;
    top: 10px;
    right: 20px;
    height: 40px;
    position: absolute;
    width: 40px;

    .material-icons {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 30px;
      color: #7d829e;
    }
  }
}

.attachment-item {
  position: relative;

  .attachment-remove {
    position: absolute;
    top: 4px;
    right: 4px;

    .remove-btn {
      @extend %close-btn;
    }
  }

  .attachment-item-image {
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 100px;
    width: 100px;
    border-radius: 10px;
    border: 1px solid #f0f0f6;
    overflow: hidden;
    justify-content: center;
  }

  .attachment-item-file {
    cursor: pointer;
    display: flex;
    padding: 0 10px;
    justify-content: center;
    height: 100px;
    align-items: center;
    max-width: 250px;
    background-color: #f8f8fc;
    border: 1px solid #f0f0f6;
    border-radius: 10px;

    .attachment-name {
      min-width: 0;
    }

    .attachment-icon {
      padding-right: 10px;

      .material-icons {
        font-size: 25px;
        color: #7d829e;
      }
    }
  }
}
