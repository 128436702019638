@import "../../../styles/common";

.scale-summary-pages {
  margin-top: 20px;
  max-height: calc(100vh - 440px);
  .scale-summary-body {
    min-height: 200px;
    max-height: calc(100vh - 260px);
  }
  .summary-items {
    .summary-item {
      @extend %category-item;
      box-shadow: 0 1px 0 0 #d1d2de;
      margin-bottom: 1px;

      &:hover {
        background-color: #fcf8f8;
      }
      .right-side {
        display: flex;
        align-items: center;
      }
    }
    .summary-item-selected {
      background-color: #fcf8f8;
    }
  }

  .divider {
    border: 1px solid #d1d2de;
    margin: 40.5px 0;
  }
}
