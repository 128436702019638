.calculator {
  width: 100%;
  margin: 0;

  .calculator-col {
    padding: 0;
  }

  .calculator-inputs-col {
    padding: 0 0 0 40px;
  }

  .calculator-input {
    max-width: 460px;

    .calc-textarea-container {
      position: relative;
      text-align: right;

      .calc-textarea {
        margin-bottom: 40px;
        font-size: 30px;
        border: 1px solid #d1d2de;
        background-color: #f8f8fc;
        padding: 20px;
      }

      .calculator-result {
        position: absolute;
        bottom: 1px;
        right: 0;
        background-color: #f8f8fc;
        margin-right: 35px;
        height: 38px;
        width: calc(100% - 37px);
        font-size: 22px;
      }
    }

    .calc-row {
      justify-content: space-between;
      margin: 0;
    }

    .btn-col {
      max-width: 120px;
      padding: 0 10px;

      .calc-btn {
        width: 100px;
        height: 100px;
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0;
        }

        .icon-centered {
          margin: 0;
        }
      }

      &:last-child {
        max-width: 110px;
        padding-right: 0;
      }

      &:first-child {
        max-width: 110px;
        padding-left: 0;
      }
    }
  }

  .calculator-form {
    .form-column {
      padding: 0;
    }
  }

  .calculator-form-controls {
    margin-top: 64px;
  }
}
