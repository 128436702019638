.quote-overview {
  &:hover {
    text-decoration: none;
  }
  .quote-contact {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #eee;
    display: block;
    text-decoration: none;
  }
}
