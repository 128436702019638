.avatar-uploader {
  border-radius: 50%;
  display: flex;
  background-color: #f8f8fc;
  justify-content: center;
  align-items: center;
  height: 200px;
  width: 200px;
  cursor: pointer;
  user-select: none;
  position: relative;
  outline: none;

  .material-icons {
    font-size: 96px;
    color: #7d829e;
  }

  .avatar-preview {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    img {
      background-color: #f8f8fc;
    }
  }
}
