@import "../../../styles/variables";

.lead-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  font-size: 16px;
  padding: 10px;

  background: #fff;
  border-radius: 3px;
  border-bottom: 1px solid #ccc;

  position: relative;

  .lead-name {
    font-weight: bold;
  }
  .lead-closeDay {
    font-size: 12px;
    color: #7d829e;
  }
  .lead-budget {
    color: #3fbb64;
  }
  .lead-tag {
    background-color: #e8e9eb;
    font-weight: 400;
    margin-right: 5px;
  }
  .lead-assignedTo {
    font-size: 12px;
    font-weight: bold;
    color: #7d829e;
  }

  .draggableStyle {
    background-color: #3fbb64;
  }
}
