@import "../../../styles/variables.scss";

.job-overview {
  .job-contact {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #eee;
    display: block;
    text-decoration: none;
    .badge {
      float: right;
      margin-left: 10px;
    }
    &:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
      border: 0;
    }
    .invite-button {
      margin: 3px;
      padding: 0px;
      min-width: 100px;
      height: 45px;

      &:hover {
        background-color: $border-color-light;
      }
    }
    .access-info {
      margin-right: 10px;
      cursor: default;
      color: $text-color-primary;
      font-weight: bold;
    }
  }
}
