.link-preview {
  border: 1px solid #f0f0f6;
  border-radius: 3px;
  padding: 15px;
  max-width: 600px;

  .link-image {
    margin-bottom: 15px;
  }

  .link {
    color: #213242;
    margin-bottom: 10px;
    display: block;
  }
}
