@import "../../styles/variables.scss";

.inputSearch-container {
  display: flex;
  position: relative;
  .inputSearch {
    padding-right: 20px;
    background-color: #314961;
    height: 60px;
    color: white;
    outline: none;
    border: none;
  }
  .inputSearch::placeholder {
    color: rgba(255, 255, 255, 0.5);
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
  }

  .cancel-icon-container {
    display: flex;
    align-items: center;
    background: none;
    outline: none;
    border: none;
    color: $text-color-light;
    opacity: 1;
    position: absolute;
    right: -10px;
    top: 50%;
    transform: translateY(-50%);
    transition: opacity 0.1s linear;
    &:hover {
      opacity: 1;
    }
    .cancel-icon {
      font-size: 20px;
    }
  }

  .search-icon {
    display: flex;
    align-items: center;
    background: none;
    font-size: 28px;
    opacity: 1;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    transition: opacity 0.1s linear;
  }
}
