.update-costing-modal {
  width: 100%;
  max-width: 100%;
  .overflow-auto {
    overflow: visible !important;
  }
  .form-input-toggle {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0px;
    }
  }

  .selected-action-button {
    width: 320px;
  }
}
