.card-wrapper {
  margin-bottom: 20px;

  .table-card {
    margin-right: 0;
  }
}

.dashboard-card {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  // max-height: 545px;
  display: flex;
  flex-direction: column;

  &.overflow-visible {
    .body {
      overflow: visible;
    }
  }

  .header {
    display: flex;
    align-items: center;
    height: 60px;
    background-color: #314961;
    padding-left: 40px;
    font-size: 20px;
    font-weight: bold;
    line-height: 24px;
    letter-spacing: -0.48px;
    text-transform: uppercase;
    color: #ffffff;
    flex-shrink: 0;

    &.centered {
      text-transform: none;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .title-badge {
      min-width: 120px;
      height: 30px;
      padding: 0 10px;
      background-color: #506479;
      font-size: 16px;
      margin-left: 40px;
      text-align: center;
      line-height: 30px;
      font-weight: normal;
    }

    .title-badge--disabled {
      background-color: #dce0e5;
    }

    .actions-container {
      display: flex;
      .action {
        user-select: none;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 15px;
        height: 100%;
        font-size: 18px;
        color: #feffff;
        letter-spacing: -0.43px;
        font-weight: 600;
        cursor: pointer;

        &:hover {
          background-color: #213242;
        }

        .icon-button {
          margin-right: 0;
        }

        i {
          font-size: 30px;
          margin-right: 5px;
        }
      }
    }
  }

  .body {
    margin: 20px;
    height: 100%;
    overflow: auto;
  }

  .footer {
    padding: 0 20px 20px;
    flex-shrink: 0;
    .button {
      margin-left: 10px;
    }
  }
}

.card-field {
  &-header {
    height: 60px;
    line-height: 60px;
  }

  &-placeholder {
    color: #d1d2de;
    font-style: italic;
  }

  &-content {
    word-break: break-word;
    overflow: hidden;
    background-color: #f8f8fc;
    min-height: 60px;
    padding: 18px;
  }
  &-text-content {
    white-space: pre;
  }
  &-text-content-wrap {
    white-space: normal;
    text-align: justify;
  }

  &-link {
    cursor: pointer;
  }
}
