.back-navigation {
  display: flex;
  align-items: center;
  height: 60px;
  padding: 0 40px;
  cursor: pointer;

  i {
    font-size: 30px;
    color: #ffffff;
  }

  .title {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    color: #feffff;
    letter-spacing: -0.43px;
    font-weight: bold;
  }
}
