$color-success: #3fbb64;
$color-primary: #314961;
$color-primary-light: #7d829e;
$color-light: #f8f8fc;
$color-neutral: #feffff;
$color-warning: #f5a623;
$color-error: #ff0707;
$color-error-light: #ca6464;
$color-primary-dark: #213242;

$border-color-primary: #d1d2de;
$border-color-secondary: #cbcbcb;
$border-color-light: #f0f0f6;

$text-color-primary-dark: #314961;
$text-color-primary: #7d829e;
$text-color-placeholder: #d1d2de;
$text-color-secondary: #8f98a0;
$text-color-light: #ffffff;

$header-height: 60px;
$compact-input-height: 40px;
$compact-input-font-size: 14px;
$compact-table-row-height: 40px;
