@import "../../../styles/common";

.checklist-categories {
  max-height: 100%;

  .checklist-body {
    min-height: 200px;
    max-height: calc(100vh - 260px);
  }

  .checklist-list {
    .checklist-list-item {
      @extend %category-item;
      box-shadow: 0 1px 0 0 #d1d2de;
      margin-bottom: 1px;
      min-height: 50px;
      &:hover {
        background-color: #fcf8f8;
      }

      .right-side {
        display: flex;
        align-items: center;
      }
    }
  }

  .checklist-category-selected {
    background-color: #fcf8f8;
  }

  .divider {
    border: 1px solid #d1d2de;
    margin: 40.5px 0;
  }
}
