@import "../../../styles/common";
@import "../../../styles/variables.scss";

.attachment {
  .file-attachment,
  .image-attachment {
    margin-top: 20px;

    img {
      max-height: 800px;
      max-width: 100%;
      height: auto;
    }
  }

  &--horizontal {
    padding: 10px;

    .file-attachment {
      margin-top: 0;
      width: 320px;
    }

    .image-attachment {
      height: 250px;

      img {
        max-height: 100%;
        width: auto;
      }
    }
  }
}

.chat-message {
  @extend %conversation-container;

  .message-content {
    display: flex;

    &.message-content-noavatar {
      .message-data {
        padding-left: 0;
      }
    }
  }

  .message-avatar-container {
    flex: 0 0 60px;
  }

  .message-data {
    padding-left: 20px;
    flex: 1 1 auto;
  }

  .message-owner {
    @extend %message-title;
  }

  .message-header {
    @extend %message-header;
  }

  .message-content {
    @extend %message-content;
  }

  .delete-message-container {
    width: 48px;
    height: 36px;

    .delete-message {
      margin-left: 5px;
      padding: 5px;
      opacity: 0;
      line-height: 0;
      outline: none;
      background: none;
      border: none;
      transition: opacity 0.2s linear;

      &:focus {
        outline: none;
        box-shadow: none;
      }
    }

    .delete-icon {
      padding: 0;
      color: $text-color-primary;
    }

    &:hover {
      .delete-message {
        background-color: $color-light;
      }
    }
  }

  .message-content:hover {
    .delete-message {
      opacity: 1;

      &:active {
        outline: none;
        box-shadow: none;
        background-color: $text-color-primary-dark;
        border: none;

        .delete-icon {
          color: white;
        }
      }
    }
  }
}
