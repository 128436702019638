%category-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  // height: 40px;
  min-height: 40px;
  font-family: Helvetica, sans-serif;
  font-size: 18px;
  color: #898da9;
  letter-spacing: -0.48px;
  cursor: pointer;
  padding: 0 10px;
  line-height: 24px;
}

%modal-header {
  width: 100%;
  height: 60px;
  background-color: #314961;
  padding: 0 40px;
  margin: 0;
  display: flex;
  align-items: center;
  border: none;
  border-radius: 0;
}

%modal-header-title {
  font-size: 20px;
  color: #f8f8fc;
  font-weight: 800;
  text-transform: uppercase;
}

%message-header {
  display: flex;
  margin-bottom: 10px;
  justify-content: space-between;
  align-items: center;
}

%message-title {
  font-size: 24px;
  font-weight: bold;
  letter-spacing: -0.58px;
  word-break: break-word;
}

%message-content {
  color: #213242;
  word-break: break-word;
}

%conversation-container {
  // padding: 40px;
  padding: 20px;
  cursor: pointer;
  border-bottom: 1px solid #f0f0f6;
  background-color: #feffff;
}

%close-btn {
  cursor: pointer;
  width: 22px;
  height: 22px;
  border-radius: 10px;
  background-color: #213242;
  opacity: 0.75;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;

  .material-icons {
    font-size: 18px;
  }
}
