.integrations {
  .integration-summary {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
    .integration-brand {
      margin-right: 20px;
    }
  }
  .btn-block {
    width: 100%;
    margin-top: 0;
  }
  .btn-block + .btn-block {
    margin-left: 0.5rem;
  }
}
