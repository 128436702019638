@import "../../styles/common.scss";
@import "../../styles/variables";

.sitediary-filters {
  height: 60px;
  margin-bottom: 15px;
}
.common-sitediary-container {
  .loaderContainer {
    margin-top: 160px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .datePickerContainer {
    background-color: $text-color-primary-dark;
    color: $text-color-light;

    .datePickerRange {
      width: 90px;
      height: 60px;
      background-color: $text-color-primary-dark;
      font-weight: bold;
      color: $text-color-light;
      cursor: pointer;
      border: none;
      outline: none;
      text-align: center;
      &:hover {
        background-color: #426383;
        border-color: #426383;
      }
      &:focus {
        background-color: #426383;
      }

      &::placeholder {
        color: $text-color-light;
      }
    }
  }
  .common-sitediary-list {
    & i {
      font-size: 30px;
    }
    .category-select-card {
      .body {
        max-height: calc(100vh - 390px);
      }
    }
  }
}
