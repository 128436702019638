.header-search {
  height: 60px;
  width: 500px;
  background-color: #213242;
  color: #ffffff;
  flex-shrink: 0;
  transition: min-width 0.2s;

  &.expanded {
    width: 500px;

    .search-container {
      .search-menu {
        width: 500px;
      }
    }
  }

  .search-container {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;

    .search-control {
      display: flex;
      width: 100%;
      height: 100%;
      padding-left: 40px;
    }

    .search-menu-input {
      width: 100%;
      border: none;
      background-color: transparent;
      outline: none;
      color: #ffffff;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: -0.48px;
      font-weight: bold;
      z-index: 1;
    }

    .search-menu {
      position: fixed;
      z-index: 10;
      top: 60px;
      width: 500px;
      background-color: #213242;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);

      .search-menu-option {
        display: flex;
        height: 60px;
        width: 100%;
        background-color: #213242;
        padding: 20px;
        justify-content: space-between;
        font-size: 20px;
        line-height: 24px;
        color: rgba(255, 255, 255, 0.5);

        &.selected {
          background-color: #314961;
        }

        &:hover {
          background-color: #314961;
        }
      }
    }

    .search-single-value {
      position: absolute;
      display: flex;
      align-items: center;
      height: 100%;
      color: #ffffff;
    }

    .search-value-container {
      position: relative;
      display: flex;
      align-items: center;

      i {
        font-size: 30px;
        opacity: 0.5;
      }

      .search-placeholder {
        opacity: 0.5;
        font-size: 18px;
        color: #feffff;
        letter-spacing: -0.43px;
        margin-left: 30px;
        position: absolute;
      }
    }
  }
}
