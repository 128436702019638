@import "../../../styles/variables.scss";

.options-container {
  padding: 0px 10px 20px 35px;
  border-bottom: 3px solid $border-color-light;

  .option-title {
    font-size: 20px;
    font-weight: 500;
    color: $color-primary;
    .option-subtitle {
      margin-top: 10px;
      font-size: 17px;
      color: $color-primary-dark;
      font-weight: bold;
      span {
        display: inline-block;
        margin-right: 30px;
      }
    }
  }

  .option-container {
    padding-top: 20px;
    border-top: 2px solid $border-color-light;
    .selected-by {
      padding: 10px;
      margin-bottom: 10px;
      font-size: 17px;
      color: $color-primary-dark;
      font-weight: bold;
      background-color: $border-color-light;
      .clientSignature-container {
        padding: 10px;
        img {
          max-height: 130px;
        }
      }
      .clientComment {
        text-align: justify;
        font-style: italic;
        white-space: pre-line;
        font-weight: 400;
      }
    }
  }
  .option-description {
    white-space: pre-line;
    text-align: justify;
    margin-bottom: 10px;
    line-height: 1.2;
    color: $color-primary-dark;
  }
  .files-conatiner {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    img {
      margin-right: 10px;
      max-height: 100px;
      max-width: 150px;
    }

    i {
      font-size: 60px;
      color: $color-primary;
      transition: transform 0.1s linear;
    }
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0px;

      &:hover {
        i {
          transform: scale(1.05);
        }
      }
    }
    .file-container {
      padding: 10px;
      margin: 5px;
      display: flex;
      flex-direction: column;
      height: 100px;
      width: 100px;
      background-color: $border-color-light;
      .file-name {
        font-size: 14px;
      }
    }
  }
}
