.quote-report {
  // height: 100%;
  // width: 100%;
  // margin: 0 auto;
  margin: -20px;
  background-color: #d8d8d8;

  .pdf-viewer {
    display: flex;
    justify-content: center;
    .react-pdf__Page__canvas {
      margin-top: 20px;
      box-shadow: 0 0 10px #aaa;
    }
  }
  .pdf-viewer:last-child {
    .react-pdf__Page__canvas {
      margin-bottom: 20px;
    }
  }
}
