.quote-design-modal {
  width: 100%;
  max-width: 100%;
  .quote-design-row {
    display: flex;
    flex-direction: row;
  }
  .quote-design-settings {
    min-width: 500px;
    max-width: 560px;
    padding: 0 20px;
    margin-bottom: 110px;
    max-height: calc(100vh - 170px);
    overflow-y: auto;
  }
  .modal-body {
    padding: 0;
  }
  .modal-footer {
    max-width: 500px;
    margin-top: -110px;
    position: relative;
    padding: 20px;
    .btn-primary {
      width: 145px;
    }
  }
  .quote-designer-preview-col {
    background-color: #d8d8d8;
    height: calc(100vh - 60px);
    width: 100%;
  }
  .quote-designer-preview {
    background-color: #d8d8d8;
    display: flex;
    justify-content: center;
    padding: 20px;
    max-height: calc(100vh - 60px);
    overflow-y: auto;
    .react-pdf__Page {
      margin-bottom: 20px;
    }
  }
  .rdw-editor-main {
    min-height: 150px;
  }
}
