%dashboard-card-tabs {
  .nav {
    width: 100%;
    display: flex;
    flex-direction: row !important;
    justify-content: space-between;
    flex-wrap: inherit;
    height: 100%;

    .nav-item {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;

      a {
        color: #ffffff;
        text-transform: none;
        opacity: 0.5;
        outline: none;

        &.active {
          opacity: 1;
        }
      }
    }
  }
}
