@import "../../../../styles/variables.scss";

.quote-selections {
  .category-container {
    overflow: inherit;
    margin: 0px;

    .category-details {
      display: flex;
      justify-content: space-between;
      padding: 20px 15px;
      border-bottom: 3px solid $border-color-light;
      text-align: justify;
      white-space: pre-line;
      .category-dueDate {
        margin-bottom: 10px;
        font-weight: 700;
      }
    }
  }

  .badge {
    margin-left: 15px;
    padding: 5px 15px;
    display: inline-block;
    border-radius: 0px;
    font-size: 16px;
    font-weight: 400;
  }

  .button-hover {
    background-color: transparent;
    &:hover {
      background-color: #213242;
    }
  }

  .create-item {
    margin-left: 30px;
    height: 40px;
    font-size: 16px;
  }
}
