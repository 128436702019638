@import "../../../styles/common";

.total {
  .total-title {
    height: 60px;
    padding-left: 20px;
    line-height: 60px;
    font-weight: bold;
  }

  .item {
    @extend %category-item;
    margin-bottom: 0;

    &:nth-child(odd) {
      background-color: #f8f8fc;
    }
  }
}
