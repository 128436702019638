.tasks-table-container {
  .table-card {
    // height: 100%;
    .dashboard-card {
      height: 100%;
    }
  }
  .dashboard-dropdown-filter {
    margin-bottom: 0px;
  }
  .relationCell {
    cursor: pointer;
    & span {
      display: block;
      width: fit-content;
      margin-top: 5px;
      &:first-child {
        margin-top: 0px;
      }
    }
  }
}
