@import "../../../styles/variables.scss";

.list-purchase-orders {
  max-width: 1420px;
  width: 100%;

  .modal-body {
    flex-direction: column;
  }

  .receipts-table-title {
    margin-top: 40px;
    margin-bottom: 10px;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: -0.48px;
    color: #7d829e;
    font-weight: bold;
  }

  .category-table {
    .table-cell-link {
      color: $color-success;
      cursor: pointer;
    }
  }
}
