.image-uploader {
  display: flex;
  background-color: rgba(128, 128, 128, 0.4);
  justify-content: center;
  align-items: center;
  height: 80px;
  width: 80px;
  cursor: pointer;
  user-select: none;
  position: relative;
  outline: none;

  .material-icons {
    font-size: 40px;
    color: white;
  }

  .image-preview {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    img {
      background-color: #f8f8fc;
    }
  }
}
